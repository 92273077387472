import React from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import onSignIn from 'actions/signin';
import SignInComponent from 'components/SignIn/SignInComponent';
import validate from './validate';

class SignInContainer extends React.PureComponent {
  submitLoginHandler = values =>
    new Promise((resolve, reject) => {
      this.props.onSignIn(values, resolve, reject);
    }).catch(response => {
      throw new SubmissionError({
        ...response.errors,
      });
    });

  render() {
    const props = {
      ...this.props,
      submitHandler: this.submitLoginHandler,
    };
    return <SignInComponent {...props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onSignIn: (values, resolve, reject) => dispatch(onSignIn(values, resolve, reject)),
  };
};
export default connect(
  null,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'signIn',
      fieldsForValidation: ['email', 'password'],
      validate,
    })(SignInContainer)
  )
);
