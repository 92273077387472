import MainLayout from 'layouts';
import routePath from 'predefined/routePath';

import GroupsContainer from 'containers/Groups/GroupsContainer';
import GroupContainer from 'containers/Groups/GroupContainer';
import DevicesContainer from 'containers/Devices/DevicesContainer';
import DeviceContainer from 'containers/Devices/DeviceContainer';
import UsersContainer from 'containers/Users/UsersContainer';

export default [
  {
    path: routePath.groups,
    exact: true,
    component: GroupsContainer,
    layout: MainLayout,
  },
  {
    path: routePath.group,
    exact: true,
    component: GroupContainer,
    layout: MainLayout,
  },
  {
    path: routePath.deviceInfo,
    exact: true,
    component: DeviceContainer,
    layout: MainLayout,
  },
  {
    path: routePath.devices,
    exact: true,
    component: DevicesContainer,
    layout: MainLayout,
  },
  {
    path: routePath.dashboard,
    exact: true,
    component: UsersContainer,
    layout: MainLayout,
  },
];
