import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import PropTypes from 'prop-types';
import PlaceholderEmptyData from 'components/ui/placeholderEmptyData';
import translate from 'predefined/translate';

const GroupsComponent = props => {
  const {
    showModalHandler,
    list,
    showGroupInfo,
    onPageChange,
    meta,
    intl: { formatMessage },
  } = props;
  const options = {
    onRowClick: row => showGroupInfo(row.id),
    onPageChange: onPageChange,
    sizePerPage: Number(process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST),
    hideSizePerPage: true,
    alwaysShowAllBtns: true,
  };

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h1 className="h3 mb-0 text-gray-800">
          <FormattedMessage id={translate.groups.title} />
        </h1>
        <Button className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm" onClick={showModalHandler}>
          <FormattedMessage id={translate.btn.group} />
        </Button>
      </div>
      {list === undefined || list.length < 1 ? (
        <PlaceholderEmptyData textPlaceholder={<FormattedMessage id={translate.groups.emptyData} />} />
      ) : (
        <Card className="shadow mb-4">
          <Card.Body className="pb-0">
            <BootstrapTable
              data={list}
              pagination
              // className="pb-5 mb-5"
              hover={true}
              options={options}
              fetchInfo={{ dataTotalSize: meta.count }}
              remote={true}
            >
              <TableHeaderColumn dataField="id" isKey width="5%">
                <FormattedMessage id="groups.table.header.column.id" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="name" dataSort width="12%">
              <FormattedMessage id="groups.table.header.column.name" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="description" width="25%">
              <FormattedMessage id="groups.table.header.column.description" />            
              </TableHeaderColumn>
              {/*<TableHeaderColumn dataField="name" dataSort width="20%" filter={{ type: 'TextFilter', delay: 600 }}>
                {formatMessage({ id: 'groups.table.header.column.name' })}
              </TableHeaderColumn>
              <TableHeaderColumn dataField="description" width="25%" filter={{ type: 'TextFilter', delay: 600 }}>
                {formatMessage({ id: 'groups.table.header.column.description' })}*/}
              <TableHeaderColumn dataField="start_date">
                <FormattedMessage id="groups.table.header.column.startDate" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="end_date">
                <FormattedMessage id="groups.table.header.column.endDate" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="time_interval" width="10%">
                <FormattedMessage id="groups.table.header.column.timeInterval" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="active_user_count" width="8%">
                <FormattedMessage id="groups.table.header.column.activeUser" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="user_count" width="5%">
                <FormattedMessage id="groups.table.header.column.allUser" />
              </TableHeaderColumn>
              <TableHeaderColumn dataField="status">Status</TableHeaderColumn>
            </BootstrapTable>
          </Card.Body>
        </Card>
      )}
    </Fragment>
  );
};

GroupsComponent.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  showGroupInfo: PropTypes.func.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string.isRequired,
      time_interval: PropTypes.number.isRequired,
      active_user_count: PropTypes.number.isRequired,
      user_count: PropTypes.number.isRequired,
      status: PropTypes.string.isRequired,
    })
  ),
  onPageChange: PropTypes.func.isRequired,
};
export default injectIntl(GroupsComponent);
