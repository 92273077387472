const routePath = {
  dashboard: '/',
  login: '/login',
  registration: '/registration',
  confirmation: '/confirmation',
  resetPassword: '/reset_password',
  groups: '/groups',
  group: '/groups/:id',
  devices: '/devices',
  deviceInfo: '/devices/:id',
  users: '/users/',
  notFound: '/404',
};

export default routePath;
