import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderField from 'components/ui/customField';
import translate from 'predefined/translate';
import { minMaxNumberGenerateUsers } from 'components/ui/customField';

const ModalGenerateUserInGroupComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting, showModalHandler, show } = props;

  return (
    <Modal
      show={show}
      onHide={() => showModalHandler('showModalGenerateUsersInGroup')}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id={translate.groupUser.generate.title} className="justify-content-center" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="user" onSubmit={handleSubmit(submitHandler)}>
          <div className="form-group row">
            <div className="col-12 mb-3 mb-sm-0">
              <Field
                component={renderField}
                type="number"
                className="form-control form-control-user"
                name="quantity"
                aria-describedby="quantity users"
                placeholder={formatMessage({ id: translate.placeholder.generate.quantity })}
                normalize={minMaxNumberGenerateUsers}
              />
            </div>
          </div>
          <Button className="btn btn-primary btn-user btn-block" type="submit" disabled={pristine || submitting}>
            <FormattedMessage id={translate.btn.generateUser} />
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalGenerateUserInGroupComponent.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default ModalGenerateUserInGroupComponent;
