import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import locale from 'reducers/locale';
import signInReducer from 'reducers/signin';
import signUpReducer from 'reducers/signup';
import group, { listOfGroups } from 'reducers/group';
import listOfDevices, { device } from 'reducers/device';
import users from 'reducers/users';

const appReducer = combineReducers({
  form: formReducer,
  locale,
  signInReducer,
  signUpReducer,
  group,
  listOfGroups,
  listOfDevices,
  device,
  users,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
