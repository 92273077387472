import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import UsersComponent from 'components/Users/UsersComponent';
import getListOfUsers, { removeUser } from 'actions/users';
import { getListGroups } from 'actions/group';
import ModalCreateUserContainer from 'containers/Common/ModalCreateUserContainer';
import LoaderComponent from 'components/ui/LoaderComponent';
import DashboardComponent from 'components/Dashboard/DashboardComponent';
import getListDevices from 'actions/device';
import ModalInformation from 'components/ui/ModalInformation';
import translate from 'predefined/translate';
import { FormattedMessage } from 'react-intl';
import ModalRequestDateTime from 'components/ui/ModalRequestDateTime';
import exportToCSVMeasurements from 'actions/export';

class UsersContainer extends React.PureComponent {
  state = {
    showModalCreateUser: false,
    userData: undefined,
    showModalRequestDateTime: false,
    selectedUserIds: false,
    showModalInformation: false,
  };
  componentWillMount() {
    this.props.getListOfUsers();
    this.props.getListOfGroups();
    this.props.getListDevices();
  }

  onPageChange = (page, sizePerPage) => {
    this.props.getListOfUsers(page, sizePerPage);
  };

  showModalCreateUser = () => {
    this.setState({ showModalCreateUser: !this.state.showModalCreateUser, userData: undefined });
  };

  deleteUser = userIds => {
    userIds.map(id => this.props.removeUser(id));
  };

  showUserInfo = userId => {
    const { listOfUsers } = this.props;
    listOfUsers.users.map(user => {
      if (userId === user.id) {
        this.setState({
          userData: user,
          showModalCreateUser: true,
        });
        return true;
      }
    });
    return false;
  };

  showModal = name => {
    this.setState({ [name]: !this.state[name] });
  };

  exportToCSVListUsers = userId => {
    this.setState({ selectedUserIds: userId });
    this.showModal('showModalRequestDateTime');
  };

  export = (types, id, values) => {
    this.props.exportToCSVMeasurements(types, id, values);
  };

  exportToCSV = values => {
    const { selectedUserIds } = this.state;
    if (selectedUserIds) {
      selectedUserIds.map(user => this.export('user', user, values));
      this.setState({ selectedUserIds: false });
    }
    this.showModal('showModalRequestDateTime');
    this.setState({ showModalInformation: true });
  };

  render() {
    const { loading, listOfUsers, listOfGroups, listOfDevices } = this.props;
    if (loading || listOfUsers === undefined || listOfGroups === undefined || listOfDevices === undefined) {
      return <LoaderComponent />;
    }

    return (
      <Fragment>
        <DashboardComponent listOfUsers={listOfUsers} listOfGroups={listOfGroups} listOfDevices={listOfDevices} />
        <UsersComponent
          listOfUsers={listOfUsers}
          deleteUser={this.deleteUser}
          showUserInfo={this.showUserInfo}
          showModalCreateUser={this.showModalCreateUser}
          listOfDevices={listOfDevices}
          onPageChange={this.onPageChange}
          exportToCSVListUsers={this.exportToCSVListUsers}
        />

        <ModalCreateUserContainer
          show={this.state.showModalCreateUser}
          // groupId={this.state.groupId}
          showModalCreateUser={this.showModalCreateUser}
          listOfGroups={listOfGroups}
          userData={this.state.userData}
        />
        <ModalInformation
          show={this.state.showModalInformation}
          showModal={this.showModal}
          textHeader={<FormattedMessage id={translate.groups.exportCSVModalText} />}
        />
        <ModalRequestDateTime
          {...this.props}
          submitHandler={this.exportToCSV}
          show={this.state.showModalRequestDateTime}
          showModalHandler={this.showModal}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfUsers: state.users.listOfUsers,
    listOfGroups: state.listOfGroups.list,
    listOfDevices: state.listOfDevices.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListOfUsers: (page, perPage) => dispatch(getListOfUsers(page, perPage)),
    getListOfGroups: () => dispatch(getListGroups()),
    removeUser: id => dispatch(removeUser(id)),
    getListDevices: () => dispatch(getListDevices()),
    exportToCSVMeasurements: (types, id, values) => dispatch(exportToCSVMeasurements(types, id, values)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersContainer);
