import AxiosHelper from 'axiosHelper';
import { takeEvery, call, put, select } from 'redux-saga/effects';
import {
  CREATE_GROUP,
  GET_LIST_GROUPS,
  GET_GROUP_DATA,
  GET_GROUP_OF_USERS,
  REMOVE_USER_FROM_GROUP,
  CREATE_USER,
  ASSIGN_USER_TO_GROUP,
  GET_USER_INFO,
  UPDATE_GROUP_SETTINGS,
  DELETE_GROUP,
  SEARCH_USERS_TO_ASSIGNED,
  REQUEST,
  SUCCESS,
  ERROR,
} from 'constants/actions';
import apiPath from 'predefined/apiPath';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';
import { getUserInfoSelectors } from 'selectors/users';
import { getUserInfoFromId } from './users';

export function* searchUsersToAssigned({ filterValue, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.get, apiPath.listOfUsers + `?name_like=${filterValue}`);

    yield put({ type: SEARCH_USERS_TO_ASSIGNED + SUCCESS, data: response.data });
    yield call(resolve);
  } catch (error) {
    yield put({ type: SEARCH_USERS_TO_ASSIGNED + ERROR, error });
    yield call(reject);
  }
}

export function* watchSearchUsersToAssigned() {
  yield takeEvery(SEARCH_USERS_TO_ASSIGNED + REQUEST, searchUsersToAssigned);
}

export function* deleteGroup({ groupId }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.delete, apiPath.deleteGroup + groupId);
    yield put({ type: DELETE_GROUP + SUCCESS, group: response.data.group });
    yield call(history.push, routePath.groups);
  } catch (error) {
    yield put({ type: DELETE_GROUP + ERROR, error });
  }
}

export function* watchDeleteGroup() {
  yield takeEvery(DELETE_GROUP + REQUEST, deleteGroup);
}

export function* updateGroupSettings({ groupId, values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.put, apiPath.updateGroupSettings + groupId, values);
    yield put({ type: UPDATE_GROUP_SETTINGS + SUCCESS, data: response.data.group });
    yield call(resolve);
  } catch (error) {
    yield put({ type: UPDATE_GROUP_SETTINGS + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export function* watchUpdateGroupSettings() {
  yield takeEvery(UPDATE_GROUP_SETTINGS + REQUEST, updateGroupSettings);
}

export function* getUserInfo({ user_id, groupId }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getUserInfo + groupId + '/users/' + user_id);

    yield put({ type: GET_USER_INFO + SUCCESS, user: response.data.user });
  } catch (error) {
    yield put({ type: GET_USER_INFO + ERROR, error });
  }
}

export function* watchGetUserInfo() {
  yield takeEvery(GET_USER_INFO + REQUEST, getUserInfo);
}

export function* assignedUserToGroup({ user_id, groupId }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.post, apiPath.assignedUserToGroup + groupId + '/group_users', user_id);

    const userId = response.data.group_user.user_id;
    let userInfo = yield select(getUserInfoSelectors(userId));

    if (userInfo === undefined || userInfo === []) {
      yield call(getUserInfoFromId, { userId: userId });
      userInfo = yield select(getUserInfoSelectors(userId));
    }

    yield put({ type: ASSIGN_USER_TO_GROUP + SUCCESS, user: response.data.group_user, userInfo });
  } catch (error) {
    yield put({ type: ASSIGN_USER_TO_GROUP + ERROR, error });
  }
}

export function* watchAssignedUserToGroup() {
  yield takeEvery(ASSIGN_USER_TO_GROUP + REQUEST, assignedUserToGroup);
}

export function* createUser({ values, groupId, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const url = groupId === undefined ? apiPath.createUserWithoutGroup : apiPath.createUser + groupId + '/users';
    const response = yield call(api.post, url, values);
    yield put({ type: CREATE_USER + SUCCESS, user: response.data.user });
    yield call(resolve);
  } catch (error) {
    yield put({ type: CREATE_USER + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export function* watchCreateUser() {
  yield takeEvery(CREATE_USER + REQUEST, createUser);
}

export function* removeUserFromGroup({ groupId, userId }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.delete, apiPath.removeUserFromGroup + groupId + '/group_users?user_id=' + userId);
    yield put({ type: REMOVE_USER_FROM_GROUP + SUCCESS, userId });
  } catch (error) {}
}
export function* watchRemoveUserFromGroup() {
  yield takeEvery(REMOVE_USER_FROM_GROUP + REQUEST, removeUserFromGroup);
}

export function* getGroupOfUsers({ groupId, page, perPage }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getGroupOfUsers + groupId + `/users?page=${page}&per_page=${perPage}`);
    yield put({ type: GET_GROUP_OF_USERS + SUCCESS, data: response.data });
  } catch (error) {}
}

export function* watchGetGroupOfUsers() {
  yield takeEvery(GET_GROUP_OF_USERS + REQUEST, getGroupOfUsers);
}

export function* getGroupData({ id }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getGroupData + id);
    yield put({ type: GET_GROUP_DATA + SUCCESS, data: response.data.group });
    yield put({ type: GET_GROUP_OF_USERS + REQUEST, groupId: id });
  } catch (error) {}
}

export function* watchGetGroupData() {
  yield takeEvery(GET_GROUP_DATA + REQUEST, getGroupData);
}

export function* getListGroups({ page, perPage }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getListGroups + `?page=${page}&per_page=${perPage}`);
    yield put({ type: GET_LIST_GROUPS + SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: GET_LIST_GROUPS + ERROR, error });
  }
}

export function* watchGetListGroups() {
  yield takeEvery(GET_LIST_GROUPS + REQUEST, getListGroups);
}

export function* createGroup({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.post, apiPath.createGroup, values);

    yield put({ type: CREATE_GROUP + SUCCESS, group: response.data.group });
    yield call(resolve, response.data.group);
  } catch (error) {
    yield put({ type: CREATE_GROUP + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export default function* watchCreateGroup() {
  yield takeEvery(CREATE_GROUP + REQUEST, createGroup);
}
