import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';
import ModalAddUserFromGroupComponent from 'components/Groups/ModalAddUserFromGroupComponent';
import { assignUserToGroup } from 'actions/group';
import getListOfUsers from 'actions/users';
import _ from 'lodash';

class ModalAddUserFromGroupContainer extends React.PureComponent {
  componentWillMount() {
    if (this.props.listOfUsers === undefined) {
      this.props.getListOfUsers();
    }
  }

  submitHandler = values => {
    Promise.all(
      values.user.map(user => this.props.assignUserToGroup({ user_id: user.value }, this.props.groupId))
    ).then(() => {
      this.props.showModalHandler();
    });
  };

  createOptionUserList = () => {
    const options = [];

    const { listOfAllUsers, listOfUsers } = this.props;
    if (listOfAllUsers === undefined || listOfAllUsers.length < 1) return options;
    let difference = _.differenceWith(listOfAllUsers, listOfUsers, _.isEqual); // eslint-line disabled
    difference.map(option =>
      options.push({ value: option.id, label: `${option.name}${option.email ? ' - ' + option.email : ''}` })
    );
    return options;
  };

  render() {
    return (
      <ModalAddUserFromGroupComponent
        {...this.props}
        submitHandler={this.submitHandler}
        optionUserList={this.createOptionUserList()}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfAllUsers: state.users.listOfUsers,
    listOfUsers: state.group.listOfUsers,
    refresh: state.group.refresh,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    assignUserToGroup: (userId, groupId) => dispatch(assignUserToGroup(userId, groupId)),
    getListOfUsers: () => dispatch(getListOfUsers()),
  };
};

ModalAddUserFromGroupContainer.propTypes = {
  groupId: PropTypes.number.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'assigneUserInGroup',
      fieldsForValidation: ['userId'],
    })(ModalAddUserFromGroupContainer)
  )
);
