import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { CircleLoader } from 'react-spinners';

const PlaceholderEmptyData = props => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Card className="shadow mb-4 w-50">
        <Card.Header className="py-3 d-flex justify-content-center">
          <CircleLoader sizeUnit={'px'} size={150} color={'#4e73df'} />
        </Card.Header>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <h4>{props.textPlaceholder}</h4>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

PlaceholderEmptyData.propTypes = {
  textPlaceholder: PropTypes.object.isRequired,
};

export default PlaceholderEmptyData;
