import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

const ModalInformation = props => {
  return (
    <Modal size="lg" show={props.show} onHide={() => props.showModal('showModalInformation')}>
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-sm">{props.textHeader}</Modal.Title>
      </Modal.Header>
    </Modal>
  );
};

ModalInformation.propTypes = {
  showModal: PropTypes.func.isRequired,
  show: PropTypes.bool,
  textHeader: PropTypes.object,
};

export default ModalInformation;
