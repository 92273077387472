import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl } from 'react-intl';
import { setNewPassword } from 'actions/resetPassword';
import ChangePasswordFormComponent from 'components/ResetPassword/ChangePasswordFormComponent';
import validate from './validateChangePasswordForm';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';

class ChangePasswordFormContainer extends React.Component {
  static propTypes = {
    setNewPassword: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { errorToken: null };
  }

  componentWillMount() {
    if (!this.props.token) {
      history.push(routePath.notFound);
      return;
    }
  }

  submitHandler = values =>
    new Promise((resolve, reject) => {
      values ? this.props.setNewPassword({ ...values, token: this.props.token }, resolve, reject) : reject();
    }).catch(error => {
      if (error.errors.token) {
        this.setState({ errorToken: error.errors.token });
      }
      throw new SubmissionError({ ...error.errors });
    });

  render() {
    const props = { ...this.props, submitHandler: this.submitHandler, errorToken: this.state.errorToken };

    return <ChangePasswordFormComponent {...props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setNewPassword: (values, resolve, reject) => dispatch(setNewPassword(values, resolve, reject)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'resetPassword',
      fieldsForValidation: ['password', 'passwordConfirmation'],
      validate,
    })(ChangePasswordFormContainer)
  )
);
