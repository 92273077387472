import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ConfirmationComponent from 'components/Confirmation/ConfirmationComponent';
import emailConfirmation from 'actions/emailConfirmation';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';

class ConfirmationContainer extends React.PureComponent {
  componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    if (!params.token) {
      history.push(routePath.notFound);
    }
    this.props.emailConfirmation(params.token);
  }
  render() {
    return <ConfirmationComponent {...this.props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    emailConfirmation: token => dispatch(emailConfirmation(token)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ConfirmationContainer);
