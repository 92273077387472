import React from 'react';
import PropTypes from 'prop-types';
import ModalCreateGroupComponent from 'components/Groups/ModalCreateGroupComponent';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, SubmissionError, reset } from 'redux-form';
import onCreateGroup, { onUpdateGroup, deleteGroup } from 'actions/group';
import groupStatus, { groupStatusToText } from 'predefined/group';
import _ from 'lodash';
import { generateUserInGroup } from 'actions/users';

class ModalCreateGroupContainer extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  submitHandler = values =>
    new Promise((resolve, reject) => {
      if (this.props.groupData === undefined) {
        values.status = values.status !== groupStatus.active ? 'active' : 'not_active';
      }
      this.props.groupData
        ? this.props.onUpdateGroup(this.props.groupData.id, values, resolve, reject)
        : this.props.onCreateGroup(values, resolve, reject);
    })
      .then(result => {
        let userNames = [];
        for (let i = 1; i <= values.quantity; i++) {
          userNames.push(`User ${i}`);
        }

        Promise.all(
          userNames.map(user => this.props.generateUserInGroup({ name: user, code: new Date() }, result.id))
        ).then(() => {
          this.props.showModalHandler('showModalEditSettingsGroup');
        });
      })
      .catch(response => {
        throw new SubmissionError({
          ...response.errors,
        });
      });

  deleteGroup = () => {
    let isDelete = window.confirm('Are you sure you want to delete group');
    if (isDelete) this.props.deleteGroup(this.props.groupData.id);
  };

  groupStatusLists = () => {
    const options = [];
    _.mapKeys(groupStatusToText, function(value, key) {
      options.push({ value: key, label: value });
    });
    return options;
  };

  render() {
    return (
      <ModalCreateGroupComponent
        {...this.props}
        submitHandler={this.submitHandler}
        deleteGroup={this.deleteGroup}
        groupStatusOptions={this.groupStatusLists()}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.groupData,
    listGroup: state.listOfGroups,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCreateGroup: (values, resolve, reject) => dispatch(onCreateGroup(values, resolve, reject)),
    onUpdateGroup: (idGroup, values, resolve, reject) => dispatch(onUpdateGroup(idGroup, values, resolve, reject)),
    deleteGroup: groupId => dispatch(deleteGroup(groupId)),
    reset: formName => dispatch(reset(formName)),
    generateUserInGroup: (userName, groupId) => dispatch(generateUserInGroup(userName, groupId)),
  };
};

ModalCreateGroupContainer.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  onCreateGroup: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'createGroup',
      enableReinitialize: true,
      fieldsForValidation: ['name', 'description', 'status', 'time_interval'],
    })(ModalCreateGroupContainer)
  )
);
