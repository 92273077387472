import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderField from 'components/ui/customField';
import translate from 'predefined/translate';

const ModalCreateUserComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting, showModalCreateUser, show } = props;
  const isEdit = props.userData !== undefined;
  return (
    <Modal
      show={show}
      onHide={() => showModalCreateUser('showModalCreateUser')}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage
            id={isEdit ? translate.users.editTitle : translate.createNewUser.title}
            className="justify-content-center"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="user" onSubmit={handleSubmit(submitHandler)}>
          <div className="form-group row">
            <div className="col-12 mb-3 mb-sm-0">
              <Field
                component={renderField}
                type="text"
                className="form-control form-control-user"
                name="name"
                aria-describedby="nameHelp"
                placeholder={formatMessage({ id: translate.placeholder.name })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 mb-3 mb-sm-0">
              <Field
                className="form-control form-control-user"
                name="email"
                type="email"
                component={renderField}
                label="email"
                placeholder={formatMessage({ id: translate.placeholder.email })}
              />
            </div>
          </div>
          <Button className="btn btn-primary btn-user btn-block" type="submit" disabled={pristine || submitting}>
            <FormattedMessage id={isEdit ? translate.btn.editUser : translate.btn.createUser} />
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalCreateUserComponent.propTypes = {
  showModalCreateUser: PropTypes.func.isRequired,
  show: PropTypes.bool,
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default ModalCreateUserComponent;
