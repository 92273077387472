import { all } from 'redux-saga/effects';
import watchSignIn from './signin';
import watchSignUp from './sigup';
import watchEmailConfirmation from './emailConfirmation';
import watchSendRestorePassword, { watchSetNewPassword } from './resetPassword';
import watchCreateGroup, {
  watchGetListGroups,
  watchGetGroupData,
  watchGetGroupOfUsers,
  watchRemoveUserFromGroup,
  watchCreateUser,
  watchAssignedUserToGroup,
  watchGetUserInfo,
  watchUpdateGroupSettings,
  watchDeleteGroup,
  watchSearchUsersToAssigned,
} from './group';
import watchGetListDevices, { watchCreateDevice, watchGetDeviceInfo, watchGetDeviceUvData } from './device';
import watchListOfUsers, { watchRemoveUser, watchOnEditUser, watchGenerateUser, watchGetUserInfoFromId } from './users';
import watchLogOut from './logout';
import watchExportMeasurementsGroup from './export';

export default function* rootSaga() {
  yield all([
    watchSignIn(),
    watchSignUp(),
    watchEmailConfirmation(),
    watchSendRestorePassword(),
    watchSetNewPassword(),
    watchCreateGroup(),
    watchGetListGroups(),
    watchGetGroupData(),
    watchGetGroupOfUsers(),
    watchRemoveUserFromGroup(),
    watchCreateUser(),
    watchAssignedUserToGroup(),
    watchGetUserInfo(),
    watchGetListDevices(),
    watchCreateDevice(),
    watchGetDeviceInfo(),
    watchGetDeviceUvData(),
    watchListOfUsers(),
    watchUpdateGroupSettings(),
    watchDeleteGroup(),
    watchRemoveUser(),
    watchOnEditUser(),
    watchLogOut(),
    watchExportMeasurementsGroup(),
    watchGenerateUser(),
    watchSearchUsersToAssigned(),
    watchGetUserInfoFromId(),
  ]);
}
