import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

export default class renderSelectGroupStatus extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
  };

  componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        status: this.props.options[_.findKey(this.props.options, option => option.value === this.props.input.value)],
      });
    }
  }

  handleChange = value => {
    this.setState({
      status: value,
    });

    this.props.input.onChange(value.value);
  };

  render() {
    const { options, ...rest } = this.props;
    return <Select {...rest} options={options} onChange={this.handleChange} defaultValue={this.state.status} />;
  }
}
