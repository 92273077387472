import React from 'react';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField from 'components/ui/customField';
import routePath from 'predefined/routePath';

export const SignInComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting } = props;
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image" />
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        <FormattedMessage id="signIn.title" />
                      </h1>
                    </div>
                    <form className="user" onSubmit={handleSubmit(submitHandler)}>
                      <div className="form-group">
                        <div className="col-12">
                          <Field
                            component={renderField}
                            type="email"
                            className="form-control form-control-user"
                            name="email"
                            aria-describedby="emailHelp"
                            placeholder={formatMessage({ id: 'placeholder.email' })}
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="col-12">
                          <Field
                            className="form-control form-control-user"
                            name="password"
                            type="password"
                            component={renderField}
                            label="Password"
                            placeholder={formatMessage({ id: 'placeholder.password' })}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-primary btn-user btn-block"
                          type="submit"
                          disabled={pristine || submitting}
                        >
                          <FormattedMessage id="btn.login" />
                        </button>
                      </div>
                    </form>
                    <hr />
                    <div className="text-center">
                      <Link className="small" to={routePath.resetPassword}>
                        <FormattedMessage id="signIn.fogPass" />
                      </Link>
                    </div>
                    <div className="text-center">
                      <Link className="small" to={routePath.registration}>
                        <FormattedMessage id="signIn.createAccount" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignInComponent.propTypes = {
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default injectIntl(SignInComponent);
