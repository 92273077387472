import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderField, { timeIntervalNormalize, renderTextArea } from 'components/ui/customField';
import datePickerComponent from '../ui/datePickerComponent';
import translate from 'predefined/translate';
import renderSelectGroupStatus from 'components/ui/renderSelectGroupStatus';
import { minMaxNumberGenerateUsers } from 'components/ui/customField';

const ModalCreateGroupComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting, deleteGroup, groupStatusOptions } = props;
  const isEdit = props.groupData !== undefined;
  return (
    <Modal
      show={props.show}
      onHide={() => props.showModalHandler('showModalEditSettingsGroup')}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage
            id={isEdit ? translate.groups.titleEditModal : translate.groups.titleModal}
            className="justify-content-center"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="user" onSubmit={handleSubmit(submitHandler)}>
          <div className="form-group row">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <Field
                component={renderField}
                type="text"
                className="form-control form-control-user"
                name="name"
                aria-describedby="nameHelp"
                placeholder={formatMessage({ id: 'placeholder.group.name' })}
              />
            </div>
            <div className="col-sm-6">
              <Field
                component={renderField}
                type="number"
                className="form-control form-control-user"
                name="time_interval"
                aria-describedby="emailHelp"
                placeholder={formatMessage({ id: 'placeholder.group.timeInterval' })}
                normalize={timeIntervalNormalize}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 mb-3 mb-sm-0">
              <Field
                className="form-control form-control-user"
                name="start_date"
                type="date"
                component={datePickerComponent}
                label="startDate"
                placeholder={formatMessage({ id: 'placeholder.group.startDate' })}
              />
            </div>
            <div className="col-sm-6">
              <Field
                className="form-control form-control-user"
                name="end_date"
                type="date"
                component={datePickerComponent}
                label="endDate"
                placeholder={formatMessage({ id: 'placeholder.group.endDate' })}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-12 mb-3 mb-sm-0">
              <Field
                className="form-control form-control-user"
                name="description"
                type="date"
                row="4"
                component={renderTextArea}
                label="description"
                placeholder={formatMessage({ id: 'placeholder.group.description' })}
              />
            </div>
          </div>
          {isEdit ? (
            <div className="form-group row">
              <div className="col-12 mb-3 mb-sm-0">
                <Field
                  component={renderSelectGroupStatus}
                  name="status"
                  type="text"
                  placeholder={formatMessage({ id: 'placeholder.group.status' })}
                  options={groupStatusOptions}
                />
              </div>
            </div>
          ) : (
            <div className="form-group row">
              <div className="col-12 mb-3 mb-sm-0">
                <Field
                  component={renderField}
                  type="number"
                  className="form-control form-control-user"
                  name="quantity"
                  aria-describedby="quantity users"
                  placeholder={formatMessage({ id: translate.placeholder.generate.quantity })}
                  normalize={minMaxNumberGenerateUsers}
                />
              </div>
            </div>
          )}
          <Button className="btn btn-primary btn-user btn-block" type="submit" disabled={pristine || submitting}>
            <FormattedMessage id={isEdit ? translate.btn.editGroup : translate.btn.group} />
          </Button>
          <Button className="btn btn-danger btn-user btn-block" onClick={deleteGroup}>
            <FormattedMessage id={translate.btn.delete} />
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalCreateGroupComponent.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  groupStatusOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default ModalCreateGroupComponent;
