import Cookies from 'universal-cookie';

export const setTokenToStorage = headers => {
  const authToken = headers.authorization;
  const cookies = new Cookies();
  cookies.set('authToken', authToken);
};

export const deleteTokenFromStorage = () => {
  const cookies = new Cookies();
  cookies.remove('authToken', { path: '/' });
};
