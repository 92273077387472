import { validatePassword, validatePasswordConfirmation } from 'helpers/validations';

const validate = (values, state) => {
  const { formatMessage } = state.intl;
  const errors = {};

  errors.password = validatePassword(values.password, formatMessage);
  errors.password_confirmation = validatePasswordConfirmation(
    values.password,
    values.password_confirmation,
    formatMessage
  );

  return errors;
};

export default validate;
