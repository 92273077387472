import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_LIST_DEVICES, CREATE_DEVICE, GET_DEVICE_INFO, GET_DEVICE_UV_DATA, REQUEST, SUCCESS, ERROR } from 'constants/actions';
import AxiosHelper from 'axiosHelper';
import apiPath from 'predefined/apiPath';

export function* getDeviceInfo({ id, fromTime, toTime }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getDeviceInfo + id+ `?start_date=${fromTime.toISOString()}&end_date=${toTime.toISOString()}`);
    yield put({ type: GET_DEVICE_INFO + SUCCESS, data: response.data.device });
  } catch (error) {}
}

export function* getDeviceUvData({ id, fromTime, toTime }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getDeviceUvData + `?device_id=${id}&start_date=${fromTime.toISOString()}&end_date=${toTime.toISOString()}`);
    yield put({ type: GET_DEVICE_UV_DATA + SUCCESS, data: response.data.sensor_measurements });
  } catch (error) {}
}

export function* watchGetDeviceInfo() {
  yield takeEvery(GET_DEVICE_INFO + REQUEST, getDeviceInfo);
}

export function* watchGetDeviceUvData() {
  yield takeEvery(GET_DEVICE_UV_DATA + REQUEST, getDeviceUvData);
}

export function* createDevice({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.post, apiPath.adminCreateDevice, values);
    if (response.status >= 200 && response.status < 300) {
      yield put({ type: CREATE_DEVICE + SUCCESS, user: response.data.device });
      yield call(resolve);
    } else {
      throw response;
    }
  } catch (error) {
    yield put({ type: CREATE_DEVICE + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export function* watchCreateDevice() {
  yield takeEvery(CREATE_DEVICE + REQUEST, createDevice);
}

export function* getListDevices() {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.get, apiPath.getListDevices);
    yield put({ type: GET_LIST_DEVICES + SUCCESS, list: response.data.devices });
  } catch (error) {
    yield put({ type: GET_LIST_DEVICES + ERROR });
  }
}

export default function* watchGetListDevices() {
  yield takeEvery(GET_LIST_DEVICES + REQUEST, getListDevices);
}
