import React, { Fragment } from 'react';
import Async from 'react-select/async';
import { connect } from 'react-redux';
import { searchUsersToAssigned } from 'actions/group';
import _ from 'lodash';

class renderSelectUsersInGroup extends React.PureComponent {
  state = {
    inputValue: '',
  };

  normalizeOptions = list => {
    let options = [];
    list.map(option =>
      options.push({ value: option.id, label: `${option.name}${option.email ? ' - ' + option.email : ''}` })
    );
    return options;
  };

  filterOptions = inputValue => {
    const { listSearchAssigneeUsers, listOfUsersInGroup } = this.props;
    let difference = _.differenceWith(listSearchAssigneeUsers, listOfUsersInGroup, _.isEqual); // eslint-line disabled
    return this.normalizeOptions(difference);
  };

  promiseOptions = inputValue =>
    new Promise((resolve, reject) => {
      this.props.searchUsersToAssigned(this.props.groupId, inputValue, resolve, reject);
    }).then(() => {
      return this.filterOptions(inputValue);
    });

  handleChange = value => {
    this.props.input.onChange(value);
  };

  render() {
    const {
      meta: { touched, error },
      options,
      ...rest
    } = this.props;

    return (
      <Fragment>
        <Async {...rest} cacheOptions defaultOptions loadOptions={this.promiseOptions} onChange={this.handleChange} />
        <div className={`invalid-tooltip ${touched && error ? 'd-block' : ''}`}>{error}</div>
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    listSearchAssigneeUsers: state.group.listSearchAssigneeUsers,
    listOfUsersInGroup: state.group.listOfUsers.users,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchUsersToAssigned: (groupId, filterValue, resolve, reject) =>
      dispatch(searchUsersToAssigned(groupId, filterValue, resolve, reject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(renderSelectUsersInGroup);
