import React from 'react';
import { RingLoader } from 'react-spinners';

const LoaderComponent = () => {
  return (
    <div style={{ position: 'fixed', top: '35%', left: '50%' }}>
      <RingLoader sizeUnit={'px'} size={150} color={'#41848f'} />
    </div>
  );
};

export default LoaderComponent;
