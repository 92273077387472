import { takeEvery, call } from 'redux-saga/effects';
import { LOG_OUT, REQUEST } from 'constants/actions';
import { deleteTokenFromStorage } from 'helpers/token';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';

export function* logOut() {
  try {
    yield call(deleteTokenFromStorage);
    yield call(history.push, routePath.login);
  } catch {}
}

export default function* watchLogOut() {
  yield takeEvery(LOG_OUT + REQUEST, logOut);
}
