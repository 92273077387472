import { GET_LIST_DEVICES, GET_DEVICE_INFO, GET_DEVICE_UV_DATA, REQUEST, SUCCESS, ERROR } from 'constants/actions';

const initialState = {
  loading: false,
};

export function device(state = initialState, action) {
  const { type } = action;
  switch (type) {
    case GET_DEVICE_INFO + REQUEST:
      return { ...state, loading: true };
    case GET_DEVICE_INFO + SUCCESS:
      return { ...state, data: action.data, loading: false };
    case GET_DEVICE_INFO + ERROR:
      return { ...state, loading: false };
    case GET_DEVICE_UV_DATA + REQUEST:
      return { ...state, loading: true };
    case GET_DEVICE_UV_DATA + SUCCESS:
      return { ...state, deviceUvData: action.data, loading: false };
    case GET_DEVICE_UV_DATA + ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
}

const listOfDevices = (state = initialState, action) => {
  const { type, list } = action;

  switch (type) {
    case GET_LIST_DEVICES + REQUEST:
      return { ...state, loading: true };
    case GET_LIST_DEVICES + SUCCESS:
      return { ...state, list, loading: false };
    case GET_LIST_DEVICES + ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default listOfDevices;
