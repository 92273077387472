import React from 'react';

export const minMaxNumberGenerateUsers = value => {
  if (value === '') return '';
  if (value < 1) return 1;
  else if (value > 50) return 50;
  else return parseInt(value, 10);
};

export const timeIntervalNormalize = value => {
  if (value === '') return '';
  if (value < 1) return 1;
  else if (value > 90) return 90;
  else return parseInt(value, 10);
};

export const renderTextArea = ({ input, className, placeholder, meta: { touched, error } }) => (
  <div>
    <textarea
      {...input}
      placeholder={placeholder}
      className={touched && error ? `${className} is-invalid` : className}
    />
    <div className={`invalid-tooltip ${touched && error ? 'd-block' : ''}`}>{error}</div>
  </div>
);

const renderField = ({ input, className, placeholder, type, meta: { touched, error } }) => (
  <div>
    <input
      {...input}
      type={type}
      className={touched && error ? `${className} is-invalid` : className}
      placeholder={placeholder}
    />
    <div className={`invalid-tooltip ${touched && error ? 'd-block' : ''}`}>{error}</div>
  </div>
);

export default renderField;
