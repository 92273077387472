import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm } from 'redux-form';

import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { Field } from 'redux-form';
import translate from 'predefined/translate';
import datePickerComponent from '../ui/datePickerComponent';

class ModalRequestDateTime extends React.PureComponent {
  constructor(props) {
    super(props);
  }
  render() {
    const { formatMessage } = this.props.intl;
    const { handleSubmit, submitHandler, pristine, submitting } = this.props;

    return (
      <Modal
        show={this.props.show}
        onHide={() => this.props.showModalHandler('showModalRequestDateTime')}
        dialogClassName="modal-50w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <FormattedMessage id={translate.modal.headerTimeInterval} className="justify-content-center" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="user" onSubmit={handleSubmit(submitHandler)}>
            <div className="form-group row">
              <div className="col-sm-6 mb-3 mb-sm-0">
                <Field
                  className="form-control form-control-user"
                  name="start_datetime"
                  type="date"
                  component={datePickerComponent}
                  label="startDate"
                  placeholder={formatMessage({ id: 'placeholder.group.startDate' })}
                />
              </div>
              <div className="col-sm-6">
                <Field
                  className="form-control form-control-user"
                  name="end_datetime"
                  type="date"
                  component={datePickerComponent}
                  label="endDate"
                  placeholder={formatMessage({ id: 'placeholder.group.endDate' })}
                />
              </div>
            </div>
            <Button className="btn btn-primary btn-user btn-block" type="submit" disabled={submitting}>
              <FormattedMessage id={translate.btn.exportCSV} />
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}

ModalRequestDateTime.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
};

export default connect(
  null,
  null
)(
  injectIntl(
    reduxForm({
      form: 'modalDateTime',
      enableReinitialize: true,
      fieldsForValidation: [],
      /*fieldsForValidation: ['start_date', 'end_date'],*/
    })(ModalRequestDateTime)
  )
);
