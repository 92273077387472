import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Card } from 'react-bootstrap';
import translate from 'predefined/translate';
import fieldsName from 'predefined/fields';

const DevicesComponent = props => {
  const { listOfDevices, showDeviceInfo, concatFields, exportToCSVListDevices } = props;
  const [selectedDevices, setSelectedDevices] = useState([]);

  const createCustomButtonGroup = props => {
    return (
      <ButtonGroup className="my-custom-class mb-2" sizeClass="btn-group-sm">
        <button
          type="button"
          className={`btn btn-primary btn-sm`}
          onClick={() => exportToCSVListDevices(selectedDevices)}
          disabled={selectedDevices.length < 1}
        >
          <FormattedMessage id={translate.btn.exportCSV} />
        </button>
      </ButtonGroup>
    );
  };

  const options = {
    onRowClick: row => showDeviceInfo(row.id),
    btnGroup: createCustomButtonGroup,
  };

  const onRowSelect = (row, isSelected) => {
    const newSelected = selectedDevices;
    if (!isSelected) {
      var index = newSelected.indexOf(row.id);
      if (index !== -1) newSelected.splice(index, 1);
    } else {
      newSelected.push(row.id);
    }
    setSelectedDevices(newSelected);
  };

  const selectRowProp = {
    mode: 'checkbox',
    onSelect: onRowSelect,
  };

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h1 className="h3 mb-0 text-gray-800">
          <FormattedMessage id={translate.devices.title} />
        </h1>
      </div>

      <Card className="shadow mb-4">
        <Card.Body className="pb-5">
          <BootstrapTable
            data={listOfDevices}
            pagination
            className="pb-5 mb-5 device-uuid"
            hover={true}
            options={options}
            selectRow={selectRowProp}
          >
            <TableHeaderColumn dataField={fieldsName.id} isKey width="5%">
              <FormattedMessage id={translate.common.id} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.uuid}>              
              <FormattedMessage id={translate.common.deviceUUID} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="users" dataSort width="15%" dataFormat={concatFields}>
              <FormattedMessage id={translate.users.name} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="groups" dataFormat={concatFields} width="20%">
              <FormattedMessage id={translate.groups.name} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.battery_level}>
              <FormattedMessage id={translate.device.batteryLevel} />
            </TableHeaderColumn>            
            <TableHeaderColumn dataField={fieldsName.averageUvi}>
              <FormattedMessage id={translate.common.averageUvi} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.lastUvi}>
              <FormattedMessage id={translate.common.lastUvi} />
            </TableHeaderColumn>
          </BootstrapTable>          
        </Card.Body>
      </Card>

      
    </Fragment>
  );
};

DevicesComponent.propTypes = {
  showDeviceInfo: PropTypes.func.isRequired,
  exportToCSVListDevices: PropTypes.func.isRequired,
  listOfDevices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
};

export default injectIntl(DevicesComponent);
