import React, { Component, Fragment } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { history } from './helpers/history';
import SignInContainer from './containers/SignIn/SignInContainer';
import SignUpContainer from './containers/SignUp/SignUpContainer';
import ConfirmationContainer from 'containers/Confirmation/ConfirmationContainer';
import RootResetPasswordContainer from 'containers/ResetPassword/RootResetPasswordContainer';
import routes from 'routes';
import routePath from 'predefined/routePath';

class App extends Component {
  render() {
    const AppRoute = ({ component: Component, layout: Layout, exact, path, component, ...rest }) => (
      <Route
        path={path}
        key={path}
        exact={exact}
        {...rest}
        render={props => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );

    return (
      <Router history={history}>
        <Switch>
          <Route exact path={routePath.registration} component={SignUpContainer} />
          <Route exact path={routePath.login} component={SignInContainer} />
          <Route exact path={routePath.confirmation} component={ConfirmationContainer} />
          <Route exact path={routePath.resetPassword} component={RootResetPasswordContainer} />
          {true ? (
            <Fragment>
              {routes.map(({ path, component, exact, layout }) => (
                <AppRoute path={`${path}`} component={component} exact={exact} key={`${path}`} layout={layout} />
              ))}
            </Fragment>
          ) : (
            <SignInContainer />
          )}
        </Switch>
      </Router>
    );
  }
}

export default App;
