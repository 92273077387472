import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Tab, Row, Col, Nav, Card } from 'react-bootstrap';
import translate from 'predefined/translate';
import deviceStatus from 'predefined/device';
import GroupsTabComponent from 'components/Devices/Tabs/GroupsTabs';
import DaysTabComponent from 'components/Devices/Tabs/DaysTabs';
import UsersTabComponent from 'components/Devices/Tabs/UsersTabs';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import moment from 'moment'

const tabsKey = {
  groups: 'groups',
  users: 'users',
  days: 'days',
};

const DeviceComponent = props => {
  const { deviceData, deviceUvData, deviceStatusNow, groups, users, days_recorded } = props;

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h1 className="h3 mb-0 text-gray-800 device-uuid">
          <FormattedMessage id={translate.device.title} />          
            {deviceData.uuid}
        </h1>
      </div>
      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div
            className={`card ${
              deviceStatus.active === deviceStatusNow ? 'bg-success' : 'bg-secondary'
            } text-white shadow h-100 py-2`}
          >
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                <div className="text-s font-weight-normal text-white text-lowercase mb-1">
                    <FormattedMessage id={translate.device.statusTitle} />
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-white-800">
                    <FormattedMessage
                      id={
                        deviceStatus.active === deviceStatusNow ? translate.common.active : translate.common.notActive
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-s font-weight-normal text-primary text-lowercase mb-1">
                    <FormattedMessage id={translate.device.batteryLevel} />
                  </div>
                  <div className="row no-gutters align-items-center">
                    <div className="col-auto">
                      <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">{deviceData.battery_level}%</div>
                    </div>
                    <div className="col">
                      <div className="progress progress-sm mr-2">
                        <div
                          className="progress-bar bg-info"
                          role="progressbar"
                          style={{ width: `${deviceData.battery_level}%` }}
                          aria-valuenow={deviceData.battery_level}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-clipboard-list fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card className="shadow mb-4">
        <Card.Body className="pb-5">
        <ResponsiveContainer width="100%" height={200}>
          <LineChart data={deviceUvData}>
          {/*</LineChart><LineChart width={1000} height={200} data={deviceUvData}>}*/}
            <Line type="monotone" dot={false} dataKey="uvi" stroke="#41848f" />
            <CartesianGrid stroke="#ccc" />
            <XAxis
              dataKey = 'timestamp'
              domain = {['auto', 'auto']}
              tickCount = {5}
              name = 'Time'
              tickFormatter = {(timestamp) => (moment(new Date(timestamp)).format('   HH:mm Do   '))}
              //type = 'number'
            />
            
          </LineChart>
          </ResponsiveContainer>
          <Tab.Container id="deviceInfo" defaultActiveKey={tabsKey.groups}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-inline">
                  <Nav.Item>
                    <Nav.Link eventKey={tabsKey.groups}>
                      <FormattedMessage id={translate.device.tabs.groups} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={tabsKey.days}>
                      <FormattedMessage id={translate.device.tabs.days} />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey={tabsKey.users}>
                      <FormattedMessage id={translate.device.tabs.users} />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey={tabsKey.groups}>
                    <GroupsTabComponent groups={groups} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={tabsKey.days}>
                    <DaysTabComponent days={days_recorded} />
                  </Tab.Pane>
                  <Tab.Pane eventKey={tabsKey.users}>
                    <UsersTabComponent users={users} />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>          
        </Card.Body>
      </Card>

    </Fragment>
  );
};
export default injectIntl(DeviceComponent);
