import axios from 'axios';
import Cookies from 'universal-cookie';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';

class AxiosHelper {
  build(req) {
    const cookies = new Cookies();
    const token = cookies.get('authToken');
    const authTokenHeaders = token;
    const api = this._configAxios(authTokenHeaders);
    return api;
  }

  _configAxios(authHeaders) {
    const options = {
      headers: { authorization: authHeaders },
      baseURL: process.env.REACT_APP_API_URL,
      validateStatus: status => {
        if (status === 401) {
          const cookies = new Cookies();
          cookies.remove('authToken');
          return history.push(routePath.login);
        }

        return status >= 200 && status < 300;
      },
    };
    const client = axios.create(options);

    return client;
  }
}

export default AxiosHelper;
