import {
  GET_LIST_OF_USERS,
  CREATE_USER,
  EDIT_USER,
  REMOVE_USER,
  GET_USER_INFO_FROM_ID,
  REQUEST,
  SUCCESS,
  ERROR,
} from 'constants/actions';

const initialState = {
  loading: false,
  listOfUsers: undefined,
};

const users = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case GET_LIST_OF_USERS + REQUEST:
      return { ...state, loading: true };
    case GET_LIST_OF_USERS + SUCCESS:
      return { ...state, loading: false, listOfUsers: action.data };
    case GET_LIST_OF_USERS + ERROR:
      return { ...state, loading: false };

    case CREATE_USER + SUCCESS: {
      let newList = state.listOfUsers !== undefined ? state.listOfUsers.users : [];
      newList.unshift(action.user);
      return { ...state, loading: false, listOfUsers: { ...state.listOfUsers, users: newList } };
    }
    case EDIT_USER + SUCCESS: {
      let users = state.listOfUsers !== undefined ? state.listOfUsers.users : [];
      let key = Object.keys(users).find(index => {
        return users[index].id === action.user.id;
      });
      users[key] = action.user;
      return { ...state, listOfUsers: { ...state.listOfUsers, users: users } };
    }
    case REMOVE_USER + SUCCESS: {
      let users = state.listOfUsers.users;
      let newList = users.filter(user => user.id !== action.userId);
      return { ...state, listOfUsers: { ...state.listOfUsers, users: newList } };
    }
    case GET_USER_INFO_FROM_ID + SUCCESS: {
      let newList = state.listOfUsers !== undefined ? state.listOfUsers.users : [];
      newList.unshift(action.user);
      return { ...state, loading: false, listOfUsers: { ...state.listOfUsers, users: newList } };
    }
    default:
      return state;
  }
};

export default users;
