const emailRegexp = value => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);

export const validateEmail = (value, formatMessage) => {
  if (!value) {
    return formatMessage({ id: 'validation.require' }, { field: 'email' });
  } else if (emailRegexp(value)) {
    return formatMessage({ id: 'validation.email' }, { field: 'email' });
  }
};

export const validatePassword = (value, formatMessage) => {
  if (!value) {
    return formatMessage({ id: 'validation.require' }, { field: 'password' });
  } else if (value.length < 8) {
    return formatMessage({ id: 'validation.min_length' }, { field: 'password', count: 8 });
  } else if (value.length > 20) {
    return formatMessage({ id: 'validation.max_length' }, { field: 'password', count: 20 });
  }
};

export const validatePasswordConfirmation = (password, passwordConfirm, formatMessage) => {
  if (!passwordConfirm) {
    return formatMessage({ id: 'validation.require' }, { field: 'password_confirmation' });
  } else if (passwordConfirm !== password) {
    return formatMessage({
      id: 'validation.passwordConfirmationNotMatchThePassword',
    });
  }
};
