import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import LoaderComponent from 'components/ui/LoaderComponent';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';
import { getDeviceInfo } from 'actions/device';
import { getDeviceUvData } from 'actions/device';
import DeviceComponent from 'components/Devices/DeviceComponent';
import deviceStatus from 'predefined/device';

class DeviceContainer extends React.PureComponent {
  state = {
    deviceId: parseInt(this.props.match.params.id),
  };

  componentDidMount() {
    const { deviceId } = this.state;
    if (!deviceId) {
      history.push(routePath.notFound);
    }
    var toTime = new Date();
    var fromTime = new Date();
    fromTime.setDate(fromTime.getDate() - 2);
    this.props.getDeviceInfo(deviceId, fromTime, toTime);
    this.props.getDeviceUvData(deviceId, fromTime, toTime);
  }

  getDeviceStatus = groups => {
    let status = deviceStatus.active;

    groups.map(group => {
      if (group.status == deviceStatus.active) {
        return group.status;
      }
    });
    return status;
  };

  render() {
    const { deviceData, deviceUvData} = this.props;
    if (this.props.loading || this.props.deviceData === undefined || this.props.deviceUvData === undefined) {
      return <LoaderComponent />;
    }
    
    return (
      <Fragment>
        <DeviceComponent deviceData={deviceData} deviceUvData={deviceUvData} deviceStatusNow={this.getDeviceStatus(deviceData.groups)} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    deviceData: state.device.data,
    deviceUvData: state.device.deviceUvData,    
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getDeviceInfo: (deviceId, fromTime, toTime) => dispatch(getDeviceInfo(deviceId, fromTime, toTime)),
    getDeviceUvData: (deviceId, fromTime, toTime) => dispatch(getDeviceUvData(deviceId, fromTime, toTime)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeviceContainer);
