import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { injectIntl, FormattedMessage } from 'react-intl';
import translate from 'predefined/translate';
import fieldsName from 'predefined/fields';

const UsersTabComponent = props => {
  const { users } = props;
  return (
    <BootstrapTable data={users} pagination className="pb-5 mb-5" hover={true}>
      <TableHeaderColumn dataField={fieldsName.id} isKey width="10%">
        <FormattedMessage id={translate.common.id} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.name} dataSort>
        <FormattedMessage id={translate.common.name} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.email}>
        <FormattedMessage id={translate.common.email} />
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

export default injectIntl(UsersTabComponent);
