import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import GroupComponent from 'components/Groups/GroupComponent';
import { getGroupData, removeUserFromGroup, getListOfUsersInGroup } from 'actions/group';
import { history } from 'helpers/history';
import LoaderComponent from 'components/ui/LoaderComponent';
import ModalAddUserFromGroupContainer from 'containers/Groups/ModalAddUserFromGroupContainer';
import ModalCreateUserContainer from 'containers/Common/ModalCreateUserContainer';
import routePath from 'predefined/routePath';
import ModalCreateGroupContainer from 'containers/Groups/ModalCreateGroupContainer';
import exportToCSVMeasurements from 'actions/export';
import ModalGenerateUserInGroupContainer from 'containers/Groups/ModalGenerateUserInGroupContainer';
import ModalRequestDateTime from 'components/ui/ModalRequestDateTime';
import ModalInformation from 'components/ui/ModalInformation';
import translate from 'predefined/translate';
import { FormattedMessage } from 'react-intl';

class GroupContainer extends React.PureComponent {
  state = {
    groupId: parseInt(this.props.match.params.id),
    showModalAddUserToGroup: false,
    showModalCreateUser: false,
    showModalEditSettingsGroup: false,
    showModalGenerateUsersInGroup: false,
    showModalRequestDateTime: false,
    selectedUserIds: false,
    showModalInformation: false,
  };

  componentWillMount() {
    const { groupId } = this.state;
    if (!groupId) {
      history.push(routePath.notFound);
    }
    this.props.getGroupData(groupId);
  }

  onPageChange = (page, sizePerPage) => {
    this.props.getListOfUsersInGroup(this.state.groupId, page, sizePerPage);
  };

  removeUserFromGroupHandler = userId => {
    const { groupId } = this.state;
    userId.map(id => this.props.removeUserFromGroup(groupId, id));
  };

  showModal = name => {
    this.setState({ [name]: !this.state[name] });
  };

  exportToCSVListUsers = userId => {
    this.setState({ selectedUserIds: userId });
    this.showModal('showModalRequestDateTime');
  };

  export = (types, id, values) => {
    this.props.exportToCSVMeasurements(types, id, values);
  };

  exportToCSV = values => {
    const { selectedUserIds, groupId } = this.state;
    if (selectedUserIds) {
      selectedUserIds.map(user => this.export('user', user, values));
      this.setState({ selectedUserIds: false });
    } else {
      this.export('group', groupId);
    }
    this.showModal('showModalRequestDateTime');
    this.setState({ showModalInformation: true });
  };

  showUserInfo = userId => {
    const { listOfUsers } = this.props;
    listOfUsers.users.map(user => {
      if (userId === user.id) {
        this.setState({
          userData: user,
          showModalCreateUser: true,
        });
        return true;
      }
    });
    return false;
  };

  render() {
    if (this.props.loading || this.props.groupData === undefined || this.props.listOfUsers === undefined) {
      return <LoaderComponent />;
    }

    return (
      <Fragment>
        <ModalAddUserFromGroupContainer
          show={this.state.showModalAddUserToGroup}
          groupId={this.state.groupId}
          showModalHandler={this.showModal}
        />
        <ModalCreateUserContainer
          show={this.state.showModalCreateUser}
          groupId={this.state.groupId}
          showModalCreateUser={this.showModal}
          userData={this.state.userData}
          isEditGroup={true}
        />
        <ModalCreateGroupContainer
          show={this.state.showModalEditSettingsGroup}
          showModalHandler={this.showModal}
          groupData={this.props.groupData}
        />
        <ModalGenerateUserInGroupContainer
          show={this.state.showModalGenerateUsersInGroup}
          showModalHandler={this.showModal}
          groupId={this.state.groupId}
        />
        <GroupComponent
          groupData={this.props.groupData}
          listOfUsers={this.props.listOfUsers.users}
          meta={this.props.listOfUsers.meta}
          removeUserFromGroup={this.removeUserFromGroupHandler}
          showModal={this.showModal}
          showUserInfo={this.showUserInfo}
          onPageChange={this.onPageChange}
          exportToCSVListUsers={this.exportToCSVListUsers}
        />
        <ModalRequestDateTime
          {...this.props}
          submitHandler={this.exportToCSV}
          show={this.state.showModalRequestDateTime}
          showModalHandler={this.showModal}
        />

        <ModalInformation
          show={this.state.showModalInformation}
          showModal={this.showModal}
          textHeader={<FormattedMessage id={translate.groups.exportCSVModalText} />}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.group.loading,
    groupData: state.group.data,
    listOfUsers: state.group.listOfUsers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getGroupData: id => dispatch(getGroupData(id)),
    getListOfUsersInGroup: (groupId, page, perPage) => dispatch(getListOfUsersInGroup(groupId, page, perPage)),
    removeUserFromGroup: (groupId, userId) => dispatch(removeUserFromGroup(groupId, userId)),
    exportToCSVMeasurements: (types, id, values) => dispatch(exportToCSVMeasurements(types, id, values)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupContainer));
