import React, { Fragment } from 'react';
import DevicesComponent from 'components/Devices/DevicesComponent';
import getListDevices from 'actions/device';
import { connect } from 'react-redux';
import LoaderComponent from 'components/ui/LoaderComponent';
import { history } from 'helpers/history';
import ModalRequestDateTime from 'components/ui/ModalRequestDateTime';
import ModalInformation from 'components/ui/ModalInformation';
import translate from 'predefined/translate';
import { FormattedMessage } from 'react-intl';
import exportToCSVMeasurements from 'actions/export';
import routePath from 'predefined/routePath';

class DevicesContainer extends React.PureComponent {
  state = {
    showModalCreateDevice: false,
    showModalRequestDateTime: false,
    selectedDeviceIds: false,
    showModalInformation: false,
  };

  componentWillMount() {
    this.props.getListDevices();
  }

  showModal = name => {
    this.setState({ [name]: !this.state[name] });
  };

  showDeviceInfo = idDevice => {
    history.push(`${routePath.devices}/${idDevice}`);
  };

  concatFields = cell => {
    let temps = '';
    cell.map(line => (temps = temps.concat(line.name, ', ')));
    return temps.slice(0, -2);
  };

  exportToCSVListDevices = deviceIds => {
    this.setState({ selectedDeviceIds: deviceIds });
    this.showModal('showModalRequestDateTime');
  };

  export = (types, id, values) => {
    this.props.exportToCSVMeasurements(types, id, values);
  };

  exportToCSV = values => {
    const { selectedDeviceIds } = this.state;
    if (selectedDeviceIds) {
      selectedDeviceIds.map(device => this.export('device', device, values));
      this.setState({ selectedDeviceIds: false });
    }
    this.showModal('showModalRequestDateTime');
    this.setState({ showModalInformation: true });
  };

  render() {
    const { listOfDevices } = this.props;

    if (this.props.loading || listOfDevices === undefined) {
      return <LoaderComponent />;
    }

    return (
      <Fragment>
        <DevicesComponent
          listOfDevices={listOfDevices}
          showDeviceInfo={this.showDeviceInfo}
          concatFields={this.concatFields}
          exportToCSVListDevices={this.exportToCSVListDevices}
        />
        <ModalRequestDateTime
          {...this.props}
          submitHandler={this.exportToCSV}
          show={this.state.showModalRequestDateTime}
          showModalHandler={this.showModal}
        />

        <ModalInformation
          show={this.state.showModalInformation}
          showModal={this.showModal}
          textHeader={<FormattedMessage id={translate.groups.exportCSVModalText} />}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    listOfDevices: state.listOfDevices.list,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListDevices: () => dispatch(getListDevices()),
    exportToCSVMeasurements: (types, id, values) => dispatch(exportToCSVMeasurements(types, id, values)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevicesContainer);
