import { takeEvery, call, put } from 'redux-saga/effects';
import { SIGN_UP, REQUEST, SUCCESS, ERROR } from 'constants/actions';
import AxiosHelper from 'axiosHelper';
import apiPath from 'predefined/apiPath';

export function* signUp({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.post, apiPath.registration, values);
    yield put({ type: SIGN_UP + SUCCESS });
    yield call(resolve);
  } catch (error) {
    yield put({ type: SIGN_UP + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export default function* watchSignUp() {
  yield takeEvery(SIGN_UP + REQUEST, signUp);
}
