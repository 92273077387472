import { validateEmail, validatePassword } from 'helpers/validations';

const validate = (values, state) => {
  const { formatMessage } = state.intl;
  const errors = {};

  errors.email = validateEmail(values.email, formatMessage);
  errors.password = validatePassword(values.password, formatMessage);

  return errors;
};

export default validate;
