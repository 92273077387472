import React, { Fragment } from 'react';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import SignUpComponent from 'components/SignUp/SignUpComponent';
import onSignUp from 'actions/signup';
import validate from './validate';
import SignUpModal from './modal';

class SignUpContainer extends React.PureComponent {
  state = {
    showModal: true,
  };

  submitLoginHandler = values =>
    new Promise((resolve, reject) => {
      this.props.onSignUp(values, resolve, reject);
    }).catch(response => {
      throw new SubmissionError({
        ...response.errors,
      });
    });

  hideModal = () => {
    this.setState({
      showModal: false,
    });
  };

  render() {
    const props = {
      ...this.props,
      submitHandler: this.submitLoginHandler,
    };

    let show = this.state.showModal & this.props.showModal;

    return (
      <Fragment>
        <SignUpComponent {...props} />
        <SignUpModal show={show} hideModal={this.hideModal} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    showModal: state.signUpReducer.showModal,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSignUp: (values, resolve, reject) => dispatch(onSignUp(values, resolve, reject)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'signUp',
      validate,
      fieldsForValidation: ['email', 'name', 'password', 'password_confirmation'],
    })(SignUpContainer)
  )
);
