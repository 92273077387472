import { validateEmail } from 'helpers/validations';

const validate = (values, state) => {
  const { formatMessage } = state.intl;
  const errors = {};

  errors.email = validateEmail(values.email, formatMessage);

  return errors;
};

export default validate;
