import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import routePath from 'predefined/routePath';
import MenuLinkComponent from 'components/ui/MenuLinkComponent';
import translate from 'predefined/translate';
import LogOutContainer from 'containers/Common/LogOutContainer';

const SideBarComponent = () => {
  return (
    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
      <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
        <div className="sidebar-brand-text mx-3">
          <FormattedMessage id="menu.header" />
        </div>
      </a>
      <hr className="sidebar-divider my-0" />
      <MenuLinkComponent to={routePath.dashboard} title={translate.menu.dashboard} />
      <hr className="sidebar-divider my-0" />
      <MenuLinkComponent to={routePath.groups} title={translate.menu.groups} />
      <hr className="sidebar-divider my-0" />
      <MenuLinkComponent to={routePath.devices} title={translate.menu.devices} />
      <hr className="sidebar-divider my-0" />
      <LogOutContainer />
    </ul>
  );
};

export default injectIntl(SideBarComponent);
