import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import translate from 'predefined/translate';
import fieldsName from 'predefined/fields';

const UsersComponent = props => {
  const { showModalCreateUser, listOfUsers, deleteUser, showUserInfo, onPageChange, exportToCSVListUsers } = props;
  const [selectedUsers, setSelectedUsers] = useState([]);

  const createCustomButtonGroup = props => {
    return (
      <ButtonGroup className="my-custom-class mb-2" sizeClass="btn-group-sm">        
        <button
          type="button"
          className={`btn btn-primary btn-sm mr-5`}
          onClick={() => exportToCSVListUsers(selectedUsers)}
          disabled={selectedUsers.length < 1}
        >
          <FormattedMessage id={translate.btn.exportCSV} />
        </button>
        {props.deleteBtn}
      </ButtonGroup>
    );
  };

  const onRowSelect = (row, isSelected) => {
    const newSelected = selectedUsers;
    if (!isSelected) {
      var index = newSelected.indexOf(row.id);
      if (index !== -1) newSelected.splice(index, 1);
    } else {
      newSelected.push(row.id);
    }
    setSelectedUsers(newSelected);
  };

  const selectRowProp = {
    mode: 'checkbox',
    onSelect: onRowSelect,
  };

  const options = {
    afterDeleteRow: deleteUser,
    onRowClick: row => showUserInfo(row.id),
    onPageChange: onPageChange,
    sizePerPage: Number(process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST),
    hideSizePerPage: true,
    btnGroup: createCustomButtonGroup,
  };

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h1 className="h3 mb-0 text-gray-800">
          <FormattedMessage id={translate.users.title} />
        </h1>
        {/*<Button className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm" onClick={showModalCreateUser}>
          <FormattedMessage id={translate.btn.createUser} />
        </Button>*/}
      </div>

      <Card className="shadow mb-4">
        <Card.Body className="pb-0">
          <BootstrapTable
            data={listOfUsers.users}
            pagination={true}
            hover={true}
            options={options}
            deleteRow={true}
            selectRow={selectRowProp}
            fetchInfo={{
              dataTotalSize:
                listOfUsers.meta !== undefined
                  ? listOfUsers.meta.count
                  : Number(process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST),
            }}
            remote={true}
          >
            <TableHeaderColumn dataField={fieldsName.id} isKey width="10%">
              <FormattedMessage id={translate.common.id} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.name} dataSort>
              <FormattedMessage id={translate.common.name} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.email}>
              <FormattedMessage id={translate.common.email} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.code} width="10%">
              <FormattedMessage id={translate.common.code} />
            </TableHeaderColumn>
            <TableHeaderColumn dataField={fieldsName.status} width="10%">
              <FormattedMessage id={translate.common.status} />
            </TableHeaderColumn>
          </BootstrapTable>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

UsersComponent.propTypes = {
  showModalCreateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  exportToCSVListUsers: PropTypes.func.isRequired,
};

export default injectIntl(UsersComponent);
