import React, { Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import GroupsComponent from 'components/Groups/GroupsComponent';
import ModalCreateGroupContainer from 'containers/Groups/ModalCreateGroupContainer';
import { getListGroups } from 'actions/group';
import LoaderComponent from 'components/ui/LoaderComponent';
import { history } from 'helpers/history';
import routePath from 'predefined/routePath';

class GroupsContainer extends React.PureComponent {
  state = {
    showModal: false,
  };

  componentWillMount() {
    this.props.getListGroups();
  }

  onPageChange = (page, sizePerPage) => {
    this.props.getListGroups(page, sizePerPage);
  };

  showModalHandler = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  showGroupInfo = idGroup => {
    history.push(routePath.groups + '/' + idGroup);
  };

  render() {
    if (this.props.loading) {
      return <LoaderComponent />;
    }

    const { list, meta } = this.props;
    return (
      <Fragment>
        <GroupsComponent
          showModalHandler={this.showModalHandler}
          list={list}
          showGroupInfo={this.showGroupInfo}
          onPageChange={this.onPageChange}
          meta={meta}
        />
        <ModalCreateGroupContainer show={this.state.showModal} showModalHandler={this.showModalHandler} />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.listOfGroups.loading,
    list: state.listOfGroups.list,
    meta: state.listOfGroups.meta,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getListGroups: (page, perPage) => {
      dispatch(getListGroups(page, perPage));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(GroupsContainer));
