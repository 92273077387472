import React from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import renderField from 'components/ui/customField';
import routePath from 'predefined/routePath';

const SignUpComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting } = props;

  return (
    <div className="container">
      <div className="card o-hidden border-0 shadow-lg my-5">
        <div className="card-body p-0">
          <div className="row">
            <div className="col-lg-5 d-none d-lg-block bg-register-image" />
            <div className="col-lg-7">
              <div className="p-5">
                <div className="text-center">
                  <h1 className="h4 text-gray-900 mb-4">
                    <FormattedMessage id="signUp.title" />
                  </h1>
                </div>
                <form className="user" onSubmit={handleSubmit(submitHandler)}>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <Field
                        component={renderField}
                        type="text"
                        className="form-control form-control-user"
                        name="name"
                        aria-describedby="nameHelp"
                        placeholder={formatMessage({ id: 'placeholder.name' })}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        component={renderField}
                        type="email"
                        className="form-control form-control-user"
                        name="email"
                        aria-describedby="emailHelp"
                        placeholder={formatMessage({ id: 'placeholder.email' })}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-6 mb-3 mb-sm-0">
                      <Field
                        className="form-control form-control-user"
                        name="password"
                        type="password"
                        component={renderField}
                        label="Password"
                        placeholder={formatMessage({ id: 'placeholder.password' })}
                      />
                    </div>
                    <div className="col-sm-6">
                      <Field
                        className="form-control form-control-user"
                        name="password_confirmation"
                        type="password"
                        component={renderField}
                        label="Password"
                        placeholder={formatMessage({ id: 'placeholder.passwordConfirmation' })}
                      />
                    </div>
                  </div>
                  <button
                    className="btn btn-primary btn-user btn-block"
                    type="submit"
                    disabled={pristine || submitting}
                  >
                    <FormattedMessage id="signUp.create" />
                  </button>
                </form>
                <hr />
                <div className="text-center">
                  <Link className="small" to={routePath.resetPassword}>
                    <FormattedMessage id="signUp.fogPass" />
                  </Link>
                </div>
                <div className="text-center">
                  <Link className="small" to={routePath.login}>
                    <FormattedMessage id="signUp.haveAccount" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignUpComponent.propTypes = {
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default SignUpComponent;
