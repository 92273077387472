import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage, intlShape } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card, Form } from 'react-bootstrap';
import renderField from 'components/ui/customField';
import { Field } from 'redux-form';
import routePath from 'predefined/routePath';

const ResetPasswordRequestFormComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting } = props;

  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg={6} className="d-none d-lg-block bg-password-image" />
                <Col lg={6}>
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-2">
                        <FormattedMessage id="resetPassword.title" />
                      </h1>
                      <p className="mb-4">
                        <FormattedMessage id="resetPassword.text" />
                      </p>
                    </div>
                    <Form className="user" onSubmit={handleSubmit(submitHandler)}>
                      <Form.Group>
                        <Field
                          component={renderField}
                          type="email"
                          className="form-control form-control-user"
                          name="email"
                          aria-describedby="emailHelp"
                          placeholder={formatMessage({ id: 'placeholder.email' })}
                        />
                      </Form.Group>
                      <Button
                        variant="primary"
                        type="submit"
                        className="btn-user btn-block"
                        disabled={pristine || submitting}
                      >
                        <FormattedMessage id="btn.resetPassword" />
                      </Button>
                    </Form>
                    <hr />
                    <div className="text-center">
                      <Link className="small" to={routePath.registration}>
                        <FormattedMessage id="signIn.createAccount" />
                      </Link>
                    </div>
                    <div className="text-center">
                      <Link className="small" to={routePath.login}>
                        <FormattedMessage id="signUp.haveAccount" />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

ResetPasswordRequestFormComponent.propTypes = {
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};
export default injectIntl(ResetPasswordRequestFormComponent);
