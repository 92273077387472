import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import ModalGenerateUserInGroupComponent from 'components/Groups/ModalGenerateUserInGroupComponent';
import { generateUserInGroup } from 'actions/users';

class ModalGenerateUserInGroupContainer extends PureComponent {
  constructor(props) {
    super(props);
  }

  submitHandler = async values => {
    let userNames = [];
    for (let i = 1; i <= values.quantity; i++) {
      userNames.push(`User ${i}`);
    }

    Promise.all(
      userNames.map(user => this.props.generateUserInGroup({ name: user, code: new Date() }, this.props.groupId))
    ).then(() => {
      this.props.showModalHandler('showModalGenerateUsersInGroup');
    });
  };

  render() {
    return <ModalGenerateUserInGroupComponent {...this.props} submitHandler={this.submitHandler} />;
  }
}

ModalGenerateUserInGroupContainer.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  groupId: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  reset: formName => dispatch(reset(formName)),
  generateUserInGroup: (userName, groupId) => dispatch(generateUserInGroup(userName, groupId)),
});

export default connect(
  null,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'generateUser',
      fieldsForValidation: ['count'],
    })(ModalGenerateUserInGroupContainer)
  )
);
