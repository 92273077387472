import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import renderSelectUsersInGroup from 'components/ui/renderSelectUsersInGroup';
import translate from 'predefined/translate';

const ModalAddUserFromGroupComponent = props => {
  const { formatMessage } = props.intl;
  const { handleSubmit, submitHandler, pristine, submitting, groupId } = props;

  return (
    <Modal
      show={props.show}
      onHide={() => props.showModalHandler('showModalAddUserToGroup')}
      dialogClassName="modal-30w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id={translate.groupUser.title} className="justify-content-center" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="user" onSubmit={handleSubmit(submitHandler)}>
          <div className="form-group row">
            <div className="col-12">
              <Field
                component={renderSelectUsersInGroup}
                type="text"
                name="user"
                aria-describedby="userId"
                placeholder={formatMessage({ id: translate.placeholder.insertUserNameOrEmail })}
                isMulti
                closeMenuOnSelect={false}
                groupId={groupId}
              />
            </div>
          </div>
          <Button className="btn btn-primary btn-user btn-block" type="submit" disabled={pristine || submitting}>
            <FormattedMessage id={translate.btn.addUser} />
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

ModalAddUserFromGroupComponent.propTypes = {
  showModalHandler: PropTypes.func.isRequired,
  show: PropTypes.bool,
  intl: intlShape,
  handleSubmit: PropTypes.func.isRequired,
  pristine: PropTypes.bool,
  submitHandler: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
};

export default ModalAddUserFromGroupComponent;
