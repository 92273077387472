const apiPath = {
  basePath: '/',
  registration: '/researcher/auth/registration',
  sighIn: '/researcher/auth/session',
  password: '/researcher/auth/password',
  login: '/login',
  emailConfirmation: '/researcher/auth/confirmation',
  createGroup: '/researcher/groups',
  getListGroups: '/researcher/groups',
  getGroupData: '/researcher/groups/',
  getGroupOfUsers: '/researcher/groups/', // /:id/users
  deleteGroup: '/researcher/groups/',
  updateGroupSettings: '/researcher/groups/', // :id
  removeUserFromGroup: '/researcher/groups/', // :group_id/group_users // user_id in body
  createUser: '/researcher/groups/', // /:group_id/users
  assignedUserToGroup: '/researcher/groups/', // :group_id/group_users
  getUserInfo: '/researcher/groups/', // :group_id/users/:id
  getListDevices: '/researcher/devices',
  adminCreateDevice: '/admin/devices/',
  getDeviceInfo: '/researcher/devices/', // :id
  getDeviceUvData: '/researcher/sensor_measurements', // :device_id
  listOfUsers: '/researcher/users',
  getTokeFromFileDownload: 'researcher/auth/file_download_token',
  downloadCSVMeasurementsGroup: 'researcher/groups/', // :group_id}/csv_sensor_measurements
  createUserWithoutGroup: '/researcher/users',
  updateUserWithoutGroup: '/researcher/users', // :id
  removeUser: '/researcher/users/', // :id
  getUserFromId: '/researcher/users/', // :id
  researcherCSVSensorMeasurementsByGroup: 'researcher/groups/', // :group_id/csv_sensor_measurement_requests
  researcherCSVSensorMeasurementsByUser: 'researcher/users/', // :user_id/csv_sensor_measurement_requests
  researcherCSVSensorMeasurementsByDevices: 'researcher/devices/', // :device_id/csv_sensor_measurement_requests
};

export default apiPath;
