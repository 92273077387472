import React from 'react';
import { Button } from 'react-bootstrap';
import onLogOut from 'actions/logout';
import translate from 'predefined/translate';
import { injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

class LogOutContainer extends React.PureComponent {
  render() {
    return (
      <Button
        className="text-white fixed-bottom btn-logout"
        type="button"
        onClick={() => {
          this.props.onLogOut();
        }}
      >
        <FormattedMessage id={translate.btn.logout} />
      </Button>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogOut: () => dispatch(onLogOut()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(injectIntl(LogOutContainer));
