import AxiosHelper from 'axiosHelper';
import { takeEvery, call, put } from 'redux-saga/effects';
import { SEND_RESTORE_PASSWORD_EMAIL, SEND_NEW_PASSWORD, REQUEST, SUCCESS, ERROR } from 'constants/actions';
import { history } from 'helpers/history';
import apiPath from 'predefined/apiPath';

export function* setNewPassword({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.patch, apiPath.password, values);
    yield put({ type: SEND_NEW_PASSWORD + SUCCESS });
    yield call(resolve);
    yield call(history.push, apiPath.login);
  } catch (error) {
    yield put({ type: SEND_NEW_PASSWORD + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export function* watchSetNewPassword() {
  yield takeEvery(SEND_NEW_PASSWORD + REQUEST, setNewPassword);
}

export function* sendRestorePasswordEmail({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.post, apiPath.password, values);
    yield put({ type: SEND_RESTORE_PASSWORD_EMAIL + SUCCESS });
    yield call(resolve);
  } catch (error) {
    yield put({ type: SEND_RESTORE_PASSWORD_EMAIL + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export default function* watchSendRestorePassword() {
  yield takeEvery(SEND_RESTORE_PASSWORD_EMAIL + REQUEST, sendRestorePasswordEmail);
}
