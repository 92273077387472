import {
  CREATE_GROUP,
  GET_LIST_GROUPS,
  GET_GROUP_DATA,
  REMOVE_USER_FROM_GROUP,
  CREATE_USER,
  ASSIGN_USER_TO_GROUP,
  UPDATE_GROUP_SETTINGS,
  DELETE_GROUP,
  GET_GROUP_OF_USERS,
  SEARCH_USERS_TO_ASSIGNED,
  REQUEST,
} from 'constants/actions';

export function deleteGroup(groupId) {
  return { type: DELETE_GROUP + REQUEST, groupId };
}

export function assignUserToGroup(user_id, groupId) {
  return { type: ASSIGN_USER_TO_GROUP + REQUEST, user_id, groupId };
}

export function createUser(values, groupId, resolve, reject) {
  return { type: CREATE_USER + REQUEST, values, groupId, resolve, reject };
}
export function removeUserFromGroup(groupId, userId) {
  return { type: REMOVE_USER_FROM_GROUP + REQUEST, groupId, userId };
}

export function getListGroups(page = 1, perPage = process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST) {
  return { type: GET_LIST_GROUPS + REQUEST, page, perPage };
}

export function getGroupData(id) {
  return { type: GET_GROUP_DATA + REQUEST, id };
}

export function onUpdateGroup(groupId, values, resolve, reject) {
  return { type: UPDATE_GROUP_SETTINGS + REQUEST, groupId, values, resolve, reject };
}

export function getListOfUsersInGroup(
  groupId,
  page = 1,
  perPage = process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST
) {
  return { type: GET_GROUP_OF_USERS + REQUEST, groupId, page, perPage };
}

export function searchUsersToAssigned(groupId, filterValue, resolve, reject) {
  return { type: SEARCH_USERS_TO_ASSIGNED + REQUEST, groupId, filterValue, resolve, reject };
}

export default function onCreateGroup(values, resolve, reject) {
  return { type: CREATE_GROUP + REQUEST, values, resolve, reject };
}
