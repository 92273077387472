import React from 'react';
import PropTypes from 'prop-types';
import ModalCreateUserComponent from 'components/Common/ModalCreateUserComponent';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { reduxForm, SubmissionError, reset } from 'redux-form';
import validate from '../Groups/validateCreateUser';
import { createUser } from 'actions/group';
import { onEditUser } from 'actions/users';

class ModalCreateUserContainer extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  submitHandler = values =>
    new Promise((resolve, reject) => {
      let groupId = this.props.groupId ? this.props.groupId : values.group ? values.group.value : undefined;
      this.props.userData !== undefined
        ? this.props.onEditUser(
            { name: values.name, email: values.email, code: values.code },
            this.props.userData.id,
            resolve,
            reject,
            this.props.isEditGroup
          )
        : this.props.createUser(values, groupId, resolve, reject);
    })
      .then(() => {
        this.props.reset('createUser');
        this.props.showModalCreateUser('showModalCreateUser');
      })
      .catch(response => {
        throw new SubmissionError({
          ...response.errors,
        });
      });

  optionGroupList = list => {
    const options = [];
    if (list === undefined || list.length < 1) return [];

    list.map(option => options.push({ value: option.id, label: option.name }));
    return options;
  };

  render() {
    return (
      <ModalCreateUserComponent
        {...this.props}
        submitHandler={this.submitHandler}
        optionGroupList={this.optionGroupList(this.props.listOfGroups)}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    initialValues: props.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    reset: formName => dispatch(reset(formName)),
    createUser: (values, groupId, resolve, reject) => dispatch(createUser(values, groupId, resolve, reject)),
    onEditUser: (values, userId, resolve, reject, isEditGroup) =>
      dispatch(onEditUser(values, userId, resolve, reject, isEditGroup)),
  };
};

ModalCreateUserContainer.propTypes = {
  showModalCreateUser: PropTypes.func.isRequired,
  show: PropTypes.bool,
  createUser: PropTypes.func.isRequired,
  groupId: PropTypes.number,
  isEditGroup: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'createUser',
      validate,
      enableReinitialize: true,
      fieldsForValidation: ['name'],
    })(ModalCreateUserContainer)
  )
);
