import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dateFormatters from 'predefined/dateFormatters';

export default class datePickerComponent extends React.Component {
  static propTypes = {
    input: PropTypes.shape({
      onChange: PropTypes.func,
    }).isRequired,
    meta: PropTypes.shape({
      touched: PropTypes.bool,
      error: PropTypes.string,
    }).isRequired,
    inputValueFormat: PropTypes.string,
  };

  state = {
    selectedDate: null,
  };

  componentWillMount() {
    if (this.props.input.value) {
      this.setState({
        selectedDate: moment(moment(this.props.input.value), this.props.inputValueFormat).toDate(),
      });
    }
  }

  handleChange = date => {
    this.setState({
      selectedDate: date,
    });

    this.props.input.onChange(date);
  };

  render() {
    const {
      meta: { touched, error },
      ...rest
    } = this.props;

    return (
      <Fragment>
        <DatePicker
          {...rest}
          selected={this.state.selectedDate}
          onChange={this.handleChange}
          timeInputLabel="Time:"
          /*showTimeSelect
          timeIntervals={15}*/
          timeFormat={dateFormatters.mainTimeFormat}
          dateFormat={dateFormatters.mainWithTimeFormat}
          className={touched && error ? `${rest.className} is-invalid w-100` : `${rest.className} w-100`}
          placeholderText={rest.placeholder}
        />
        <div className={`invalid-tooltip ${touched && error ? 'd-block' : ''}`}>{error}</div>
      </Fragment>
    );
  }
}
