export const REQUEST = '_REQUEST';
export const SUCCESS = '_SUCCESS';
export const ERROR = '_ERROR';

export const SIGN_IN = 'SIGN_IN';
export const SET_LOCALE = 'SET_LOCALE';
export const SIGN_UP = 'SIGN_UP';
export const EMAIL_CONFIRMATION = 'EMAIL_CONFIRMATION';
export const SEND_RESTORE_PASSWORD_EMAIL = 'SEND_RESTORE_PASSWORD_EMAIL';
export const SEND_NEW_PASSWORD = 'SEND_NEW_PASSWORD';
export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_LIST_GROUPS = 'GET_LIST_GROUPS';
export const GET_GROUP_DATA = 'GET_GROUP_DATA';
export const GET_GROUP_OF_USERS = 'GET_GROUP_OF_USERS';
export const REMOVE_USER_FROM_GROUP = 'REMOVE_USER_FROM_GROUP';
export const CREATE_USER = 'CREATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const EDIT_USER = 'EDIT_USER';
export const ASSIGN_USER_TO_GROUP = 'ASSIGN_USER_TO_GROUP';
export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_FROM_ID = 'GET_USER_INFO_FROM_ID';

export const GET_LIST_DEVICES = 'GET_LIST_DEVICES';
export const CREATE_DEVICE = 'CREATE_DEVICE';
export const GET_DEVICE_INFO = 'GET_DEVICE_INFO';
export const GET_DEVICE_UV_DATA = 'GET_DEVICE_UV_DATA';

export const GET_LIST_OF_USERS = 'GET_LIST_OF_USERS';
export const UPDATE_GROUP_SETTINGS = 'UPDATE_GROUP_SETTINGS';
export const DELETE_GROUP = 'DELETE_GROUP';
export const LOG_OUT = 'LOG_OUT';
export const EXPORT_TO_CSV_MEASUREMENTS = 'EXPORT_TO_CSV_MEASUREMENTS';
export const GENERATE_USER = 'GENERATE_USER';
export const UPDATE_USER_IN_GROUP = 'UPDATE_USER_IN_GROUP';
export const SEARCH_USERS_TO_ASSIGNED = 'SEARCH_USERS_TO_ASSIGNED';
