import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form';
import { injectIntl } from 'react-intl';
import sendRestorePasswordEmail from 'actions/resetPassword';
import ResetPasswordRequestFormComponent from 'components/ResetPassword/RequestFormComponent';
import validate from './validateResetPasswordForm';

class ResetPasswordRequestFormContainer extends React.Component {
  static propTypes = {
    sendRestorePasswordEmail: PropTypes.func.isRequired,
  };

  submitHandler = values =>
    new Promise((resolve, reject) => {
      values ? this.props.sendRestorePasswordEmail(values, resolve, reject) : reject();
    }).catch(error => {
      throw new SubmissionError({ _error: error.message });
    });

  render() {
    const props = { ...this.props, submitHandler: this.submitHandler };

    return <ResetPasswordRequestFormComponent {...props} />;
  }
}

const mapDispatchToProps = dispatch => {
  return {
    sendRestorePasswordEmail: value => dispatch(sendRestorePasswordEmail(value)),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(
  injectIntl(
    reduxForm({
      form: 'resetPassword',
      fieldsForValidation: ['email'],
      validate,
    })(ResetPasswordRequestFormContainer)
  )
);
