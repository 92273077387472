import { GET_LIST_OF_USERS, REMOVE_USER, EDIT_USER, GENERATE_USER, REQUEST } from 'constants/actions';

export function generateUserInGroup(userName, groupId) {
  return { type: GENERATE_USER + REQUEST, userName, groupId };
}

export function onEditUser(values, userId, resolve, reject, isEditGroup = undefined) {
  return { type: EDIT_USER + REQUEST, values, userId, resolve, reject, isEditGroup };
}

export function removeUser(userId) {
  return { type: REMOVE_USER + REQUEST, userId };
}

export default function getListOfUsers(page = 1, perPage = process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST) {
  return { type: GET_LIST_OF_USERS + REQUEST, page, perPage };
}
