import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import ResetPasswordRequestFormContainer from './RequestFormContainer';
import ChangePasswordFormContainer from './ChangePasswordFormContainer';

class RootResetPasswordContainer extends React.Component {
  render() {
    const params = queryString.parse(this.props.location.search);
    if (params.token) {
      return <ChangePasswordFormContainer {...this.props} {...params} />;
    }

    return <ResetPasswordRequestFormContainer {...this.props} />;
  }
}

export default connect()(RootResetPasswordContainer);
