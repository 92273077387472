import { takeEvery, call, put } from 'redux-saga/effects';
import { EXPORT_TO_CSV_MEASUREMENTS, REQUEST, ERROR } from 'constants/actions';
import AxiosHelper from 'axiosHelper';
import apiPath from 'predefined/apiPath';

export function* exportMeasurementsGroup({ types, id, values }) {
  const api = new AxiosHelper().build();

  try {
    let path = '';
    switch (types) {
      case 'group':
        path = apiPath.researcherCSVSensorMeasurementsByGroup;
        break;
      case 'user':
        path = apiPath.researcherCSVSensorMeasurementsByUser;
        break;
      case 'device':
        path = apiPath.researcherCSVSensorMeasurementsByDevices;
        break;
      default:
        path = null;
    }

    const response = yield call(api.post, path + id + '/csv_sensor_measurement_requests', values);
  } catch (error) {
    yield put({ type: EXPORT_TO_CSV_MEASUREMENTS + ERROR, error });
  }
}

export default function* watchExportMeasurementsGroup() {
  yield takeEvery(EXPORT_TO_CSV_MEASUREMENTS + REQUEST, exportMeasurementsGroup);
}
