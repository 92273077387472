const validate = (values, state) => {
  const { formatMessage } = state.intl;
  const errors = {};

  if (!values.name) {
    errors.name = formatMessage({ id: 'validation.require' }, { field: 'name' });
  }
  return errors;
};

export default validate;
