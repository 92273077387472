import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Button, Card } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn, ButtonGroup } from 'react-bootstrap-table';
import translate from 'predefined/translate';

const GroupComponent = props => {
  const [disabled, setDisabled] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const {
    groupData,
    listOfUsers,
    meta,
    removeUserFromGroup,
    showModal,
    showUserInfo,
    onPageChange,
    intl: { formatMessage },
    exportToCSVListUsers,
  } = props;

  const createCustomButtonGroup = props => {
    return (
      <ButtonGroup className="my-custom-class mb-2" sizeClass="btn-group-sm">        
        <button
          type="button"
          className={`btn btn-primary btn-sm`}
          onClick={() => exportToCSVListUsers(selectedUsers)}
          /*disabled={selectedUsers.length < 1}*/
        >
          <FormattedMessage id={translate.btn.exportCSV} />
        </button>

        <Button
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm ml-5"
            onClick={() => showModal('showModalAddUserToGroup')}
          >
            <FormattedMessage id="btn.addUser" />
          </Button>

          <Button
            className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-5 ml-5"
            onClick={() => showModal('showModalGenerateUsersInGroup')}
          >
            <FormattedMessage id="btn.generateUser" />
        </Button>
        {props.deleteBtn}
      </ButtonGroup>
    );
  };

  const options = {
    afterDeleteRow: removeUserFromGroup,
    onRowClick: row => showUserInfo(row.id),
    onPageChange: onPageChange,
    sizePerPage: Number(process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST),
    hideSizePerPage: true,
    alwaysShowAllBtns: true,
    btnGroup: createCustomButtonGroup,
  };

  const onRowSelect = (row, isSelected) => {
    const newSelected = selectedUsers;
    if (!isSelected) {
      var index = newSelected.indexOf(row.id);
      if (index !== -1) newSelected.splice(index, 1);
    } else {
      newSelected.push(row.id);
    }
    setSelectedUsers(newSelected);
  };

  const selectRowProp = {
    mode: 'checkbox',
    onSelect: onRowSelect,
  };

  function formatAverageUvi(cell, row) {
    return Number(cell.uvi).toFixed(3);      
  }

  function formatLastUvi(cell, row) {
    return Number(cell.uvi).toFixed(2);
  }

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-1">
        <h1 className="h3 mb-0 text-gray-800 mr-auto">{groupData.name}</h1>

        <Button
          className="d-none d-sm-inline-block btn btn-sm btn-secondary shadow-sm mr-1"
          onClick={() => showModal('showModalEditSettingsGroup')}
        >
          <FormattedMessage id={translate.btn.editGroup} />
        </Button>
        {/*}
        <Button
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-0"
          onClick={() => {
            setDisabled(true);
            showModal('showModalRequestDateTime');
          }}
          disabled={disabled}
        >
          <FormattedMessage id={translate.btn.exportCSV} />
        </Button>
        */}
      </div>
      <div className="d-sm-flex align-items-center justify-content-between my-3">
        <h1 className="h6 mb-0 text-gray-700 mr-auto">{groupData.description}</h1>
      </div>

      <Card className="shadow mb-4">
        <Card.Body className="pb-0">
          <div className="d-flex flex-row-reverse">
            {/*<Button
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
              onClick={() => showModal('showModalAddUserToGroup')}
            >
              <FormattedMessage id="btn.addUser" />
            </Button>

            <Button
              className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm mr-5"
              onClick={() => showModal('showModalGenerateUsersInGroup')}
            >
              <FormattedMessage id="btn.generateUser" />
            </Button>*/}          
          </div>          
          <BootstrapTable
            data={listOfUsers}
            pagination
            hover={true}
            options={options}
            deleteRow={true}
            selectRow={selectRowProp}
            fetchInfo={{
              dataTotalSize:
                meta !== undefined ? meta.count : Number(process.env.REACT_APP_API_DEFAULT_SIZE_PER_PAGE_LIST),
            }}
            remote={true}
          >
            <TableHeaderColumn dataField="id" isKey width="5%">
              <FormattedMessage id="groupUsers.table.header.column.id" />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="name" dataSort width="15%" filter={{ type: 'TextFilter', delay: 600 }}>
              {formatMessage({ id: 'groupUsers.table.header.column.name' })}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="email" width="20%" filter={{ type: 'TextFilter', delay: 600 }}>
              {formatMessage({ id: 'groupUsers.table.header.column.email' })}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="status">
              <FormattedMessage id="groupUsers.table.header.column.status" />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="device_ids">
              <FormattedMessage id="groupUsers.table.header.column.device" />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="last_uvs" dataFormat={formatLastUvi}>
              <FormattedMessage id="groupUsers.table.header.column.lastUvi" />
            </TableHeaderColumn>
            <TableHeaderColumn dataField="code">
              <FormattedMessage id="common.code" />
            </TableHeaderColumn>
          </BootstrapTable>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

GroupComponent.propTypes = {
  groupData: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  listOfUsers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string,
      status: PropTypes.string,      
      average_uvs: PropTypes.object,
      last_uvs: PropTypes.object,
      device_ids: PropTypes.arrayOf(
        PropTypes.number
      )
    })
  ),
  removeUserFromGroup: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showUserInfo: PropTypes.func.isRequired,
  onPageChange: PropTypes.func.isRequired,
  exportToCSVListUsers: PropTypes.func.isRequired,
};

export default injectIntl(GroupComponent);
