const groupStatus = {
  active: 1,
  not_active: 2,
  on_hold: 3,
  finished: 4,
};

export default groupStatus;

export const groupStatusToText = {
  active: 'Active',
  not_active: 'Not Active',
  on_hold: 'On Hold',
  finished: 'Finished',
};
