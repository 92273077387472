import {
  GET_LIST_GROUPS,
  GET_GROUP_DATA,
  GET_GROUP_OF_USERS,
  CREATE_GROUP,
  GET_USER_INFO,
  UPDATE_GROUP_SETTINGS,
  ASSIGN_USER_TO_GROUP,
  UPDATE_USER_IN_GROUP,
  SEARCH_USERS_TO_ASSIGNED,
  REMOVE_USER_FROM_GROUP,
  SUCCESS,
  ERROR,
  REQUEST,
} from 'constants/actions';

const initialState = {
  loading: false,
};

export default function group(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case GET_GROUP_DATA + REQUEST:
    case GET_USER_INFO + REQUEST: {
      return { ...state, loading: true };
    }

    case GET_GROUP_DATA + SUCCESS:
    case UPDATE_GROUP_SETTINGS + SUCCESS: {
      return { ...state, loading: false, data: action.data };
    }
    case GET_GROUP_DATA + ERROR:
    case GET_GROUP_OF_USERS + ERROR: {
      return { ...state, loading: false, data: undefined };
    }

    case GET_GROUP_OF_USERS + SUCCESS: {
      return { ...state, loading: false, listOfUsers: action.data || [] };
    }

    case ASSIGN_USER_TO_GROUP + SUCCESS: {
      let newList = state.listOfUsers !== undefined ? state.listOfUsers.users : [];
      let newUser =
        action.listOfUsers !== undefined
          ? action.listOfUsers.filter(user => user.id === action.user.user_id)[0]
          : state.listSearchAssigneeUsers !== undefined && action.user.user_id !== undefined
          ? state.listSearchAssigneeUsers.filter(user => user.id === action.user.user_id)[0]
          : action.user;

      console.log('newUser', newUser);

      if (newUser !== undefined && newUser !== null) newList.unshift(newUser);
      return { ...state, loading: false, listOfUsers: { ...state.listOfUsers, users: newList } };
    }

    case GET_USER_INFO + SUCCESS: {
      let newList = state.listOfUsers;
      if (newList === undefined) newList = [];
      newList.push(action.user);
      return { ...state, loading: false, listOfUsers: { ...state.listOfUsers, users: newList } };
    }

    case UPDATE_USER_IN_GROUP + SUCCESS: {
      let users = state.listOfUsers.users;

      let key = Object.keys(users).find(index => {
        return users[index].id === action.user.id;
      });
      users[key] = action.user;
      return { ...state, loading: false, listOfUsers: { ...state.listOfUsers, users: users } };
    }

    case SEARCH_USERS_TO_ASSIGNED + SUCCESS: {
      return { ...state, loading: false, listSearchAssigneeUsers: action.data.users };
    }

    case REMOVE_USER_FROM_GROUP + SUCCESS: {
      let users = state.listOfUsers.users;
      let newList = users.filter(user => user.id !== action.userId);
      return { ...state, listOfUsers: { ...state.listOfUsers, users: newList } };
    }

    default:
      return state;
  }
}

export function listOfGroups(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case CREATE_GROUP + SUCCESS: {
      const listGroups = state.list;
      listGroups.push(action.group);
      return { ...state, list: listGroups, loading: false };
    }

    case GET_LIST_GROUPS + REQUEST: {
      return { ...state, loading: true };
    }

    case GET_LIST_GROUPS + SUCCESS: {
      return { ...state, list: action.data.groups, meta: action.data.meta, loading: false };
    }

    default:
      return state;
  }
}
