import { takeEvery, call, put } from 'redux-saga/effects';
import { EMAIL_CONFIRMATION, REQUEST, SUCCESS, ERROR } from 'constants/actions';
import { history } from 'helpers/history';
import AxiosHelper from 'axiosHelper';
import apiPath from 'predefined/apiPath';
import routePath from 'predefined/routePath';

export function* emailConfirmation({ token }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.put, apiPath.emailConfirmation, { token: token });
    yield put({ type: EMAIL_CONFIRMATION + SUCCESS });
  } catch (error) {
    yield put({ type: EMAIL_CONFIRMATION + ERROR });
    yield call(history.push, routePath.notFound);
  }
}

export default function* watchEmailConfirmation() {
  yield takeEvery(EMAIL_CONFIRMATION + REQUEST, emailConfirmation);
}
