import { createSelector } from 'reselect';

export const getUsersList = state => {
  return state.users.listOfUsers !== undefined ? state.users.listOfUsers.users : undefined;
};

export const getUserInfoSelectors = userId =>
  createSelector(
    [getUsersList],
    users => {
      if (users === undefined) return undefined;
      let user = users.filter(user => user.id === userId);
      return user || undefined;
    }
  );
