import { CREATE_DEVICE, GET_LIST_DEVICES, GET_DEVICE_INFO, GET_DEVICE_UV_DATA, REQUEST } from 'constants/actions';

export function getDeviceInfo(id, fromTime, toTime) {
  return { type: GET_DEVICE_INFO + REQUEST, id, fromTime, toTime };
}

export function getDeviceUvData(id, fromTime, toTime) {
  return { type: GET_DEVICE_UV_DATA + REQUEST, id , fromTime, toTime};
}

export function createDevice(values, resolve, reject) {
  return { type: CREATE_DEVICE + REQUEST, values, resolve, reject };
}

export default function getListDevices() {
  return { type: GET_LIST_DEVICES + REQUEST };
}
