import { SEND_RESTORE_PASSWORD_EMAIL, SEND_NEW_PASSWORD, REQUEST } from 'constants/actions';

export function setNewPassword(values, resolve, reject) {
  return { type: SEND_NEW_PASSWORD + REQUEST, values, resolve, reject };
}

export default function sendRestorePasswordEmail(values, resolve, reject) {
  return {
    type: SEND_RESTORE_PASSWORD_EMAIL + REQUEST,
    values,
    resolve,
    reject,
  };
}
