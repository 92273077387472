const fieldsName = {
  id: 'id',
  name: 'name',
  description: 'description',
  status: 'status',
  startDate: 'start_date',
  end_date: 'end_date',
  timeInterval: 'time_interval',
  userCount: 'user_count',
  activeUserCount: 'active_user_count',
  email: 'email',
  uuid: 'uuid',
  averageUvi: 'average_uvs.average_uvi',
  lastUvi: 'last_uvs.uvi',
  code: 'code',
};

export default fieldsName;
