const translate = {
  signIn: {
    title: 'signIn.title',
    fogPass: 'signIn.fogPass',
    createAccount: 'signIn.createAccount',
  },

  signUp: {
    title: 'signUp.title',
    create: 'signUp.create',
    fogPass: 'signUp.fogPass',
    haveAccount: 'signUp.haveAccount',
    modalSuccess: {
      title: 'signUp.modalSuccess.title',
      text: 'signUp.modalSuccess.text',
    },
  },

  validation: {
    require: 'validation.require',
    email: 'validation.email',
    passwordConfirmationNotMatchThePassword: 'validation.passwordConfirmationNotMatchThePassword',
    min_length: 'validation.min_length',
    max_length: 'validation.max_length',
  },

  emailConfirmation: {
    success: 'emailConfirmation.success',
  },

  resetPassword: {
    title: 'resetPassword.title',
    text: 'resetPassword.text',
  },

  restorePassword: {
    title: 'restorePassword.title',
    text: 'restorePassword.text',
    change_my_password: 'restorePassword.change_my_password',
  },

  groups: {
    title: 'groups.title',
    titleModal: 'groups.modal.create.title',
    titleEditModal: 'groups.modal.edit.title',
    name: 'groups.name',
    emptyData: 'groups.emptyData',
    exportCSVModalText: 'groups.exportCSVModalText',
  },

  groupUser: {
    title: 'groupUsers.modal.create.title',
    settings: {
      title: 'groupUsers.modal.settings.title',
    },
    generate: {
      title: 'groupUsers.modal.generate.title',
      quantity: 'groupUsers.modal.generate.quantity',
    },
  },

  dashboard: {
    countOfUsers: 'dashboard.countOfUsers',
    countOfGroups: 'dashboard.countOfGroups',
    countOfDevices: 'dashboard.countOfDevices',
    title: 'dashboard.title',
  },

  common: {
    id: 'common.id',
    name: 'common.name',
    email: 'common.email',
    description: 'common.description',
    startDate: 'common.startDate',
    endDate: 'common.endDate',
    timeInterval: 'common.timeInterval',
    status: 'common.status',
    averageUvi: 'common.averageUvi',
    lastUvi: 'common.lastUvi',
    device: 'common.device',
    active: 'common.active',
    notActive: 'common.notActive',
    deviceUUID: 'common.deviceUUID',
    code: 'common.code',
  },

  users: {
    active: 'users.active',
    all: 'users.all',
    name: 'users.name',
    title: 'users.title',
    editTitle: 'users.editTitle',
  },

  createNewUser: {
    title: 'createNewUser.modal.title',
  },

  devices: {
    title: 'devices.title',
    modalTitle: 'devices.modal.title',
  },

  device: {
    title: 'device.title',
    batteryLevel: 'device.batteryLevel',
    statusTitle: 'device.statusTitle',
    tabs: {
      groups: 'device.tabs.groups',
      users: 'device.tabs.users',
      days: 'device.tabs.days',
    },
  },

  placeholder: {
    email: 'placeholder.email',
    password: 'placeholder.password',
    passwordConfirmation: 'placeholder.passwordConfirmation',
    name: 'placeholder.name',
    uuid: 'placeholder.uuid',
    macAddress: 'placeholder.macAddress',
    insertUserNameOrEmail: 'placeholder.insertUserNameOrEmail',
    group: {
      name: 'placeholder.group.name',
      description: 'placeholder.group.description',
      startDate: 'placeholder.group.startDate',
      endDate: 'placeholder.group.endDate',
      timeInterval: 'placeholder.group.timeInterval',
      activeGroup: 'placeholder.group.activeGroup',
      status: 'placeholder.group.status',
    },
    generate: {
      quantity: 'placeholder.generate.quantity',
    },

    code: 'placeholder.code',
  },

  menu: {
    header: 'menu.header',
    dashboard: 'menu.dashboard',
    groups: 'menu.groups',
    devices: 'menu.devices',
    users: 'menu.users',
  },

  btn: {
    close: 'btn.close',
    login: 'btn.login',
    resetPassword: 'btn.resetPassword',
    group: 'btn.create.group',
    createUser: 'btn.create.user',
    createDevice: 'btn.create.device',
    addUser: 'btn.addUser',
    settings: 'btn.settings',
    delete: 'btn.delete',
    editUser: 'btn.editUser',
    editGroup: 'btn.editGroup',
    logout: 'btn.logout',
    exportCSV: 'btn.exportCSV',
    generateUser: 'btn.generateUser',
  },

  modal: {
    headerTimeInterval: 'model.headerTimeInterval',
  },
};
export default translate;
