import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';

function ConfirmationComponent() {
  return (
    <Container>
      <Row className="row justify-content-center">
        <Col xl={10} lg={12} md={9}>
          <Card className="o-hidden border-0 shadow-lg my-5">
            <Card.Body className="p-0">
              <Row>
                <Col lg={6} className="d-none d-lg-block bg-login-image" />
                <Col lg={6}>
                  <div className="p-5">
                    <div className="text-center">
                      <h1 className="h4 text-gray-900 mb-4">
                        <FormattedMessage id="emailConfirmation.success" />
                      </h1>
                    </div>
                    <hr />
                    <div className="text-center">
                      <Link className="small" to="/login">
                        <Button>
                          <FormattedMessage id="btn.login" />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ConfirmationComponent;
