import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { injectIntl, FormattedMessage } from 'react-intl';
import translate from 'predefined/translate';
import fieldsName from 'predefined/fields';

const GroupsTabComponent = props => {
  const { groups } = props;
  return (
    <BootstrapTable data={groups} pagination className="pb-5 mb-5" hover={true}>
      <TableHeaderColumn dataField={fieldsName.id} isKey width="5%">
        <FormattedMessage id={translate.common.id} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.name} dataSort>
        <FormattedMessage id={translate.common.name} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.description}>
        <FormattedMessage id={translate.common.description} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.status}>
        <FormattedMessage id={translate.common.status} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.startDate}>
        <FormattedMessage id={translate.common.startDate} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.endDate}>
        <FormattedMessage id={translate.common.endDate} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.timeInterval}>
        <FormattedMessage id={translate.common.timeInterval} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.userCount}>
        <FormattedMessage id={translate.users.all} />
      </TableHeaderColumn>
      <TableHeaderColumn dataField={fieldsName.activeUserCount}>
        <FormattedMessage id={translate.users.active} />
      </TableHeaderColumn>
    </BootstrapTable>
  );
};

export default injectIntl(GroupsTabComponent);
