import React from 'react';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';

const MenuLinkComponent = props => {
  return (
    <li className="nav-item active">
      <Link className="nav-link" to={props.to}>
        <span>
          <FormattedMessage id={props.title} />
        </span>
      </Link>
    </li>
  );
};
export default injectIntl(MenuLinkComponent);
