import { takeEvery, call, put } from 'redux-saga/effects';
import {
  GET_LIST_OF_USERS,
  REMOVE_USER,
  EDIT_USER,
  GENERATE_USER,
  ASSIGN_USER_TO_GROUP,
  GET_USER_INFO,
  UPDATE_USER_IN_GROUP,
  GET_USER_INFO_FROM_ID,
  REQUEST,
  SUCCESS,
  ERROR,
} from 'constants/actions';
import AxiosHelper from 'axiosHelper';
import apiPath from 'predefined/apiPath';

export function* getUserInfoFromId({ userId }) {
  const api = new AxiosHelper().build();
  try {
    const response = yield call(api.get, apiPath.getUserFromId + userId);

    yield put({ type: GET_USER_INFO_FROM_ID + SUCCESS, user: response.data.user });
  } catch (error) {
    yield put({ type: GET_USER_INFO_FROM_ID + ERROR, error });
  }
}

export function* watchGetUserInfoFromId() {
  yield takeEvery(GET_USER_INFO_FROM_ID + REQUEST, getUserInfoFromId);
}

export function* generateUser({ userName, groupId }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.post, apiPath.createUser + groupId + '/users', userName);
    yield put({ type: ASSIGN_USER_TO_GROUP + SUCCESS, user: response.data.user });
    yield put({ type: GET_USER_INFO + SUCCESS, user: response.data.user });
  } catch (error) {
    yield put({ type: GENERATE_USER + ERROR, error });
  }
}

export function* watchGenerateUser() {
  yield takeEvery(GENERATE_USER + REQUEST, generateUser);
}

export function* onEditUser({ values, userId, resolve, reject, isEditGroup }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.put, apiPath.updateUserWithoutGroup + '/' + userId, values);

    yield put({ type: EDIT_USER + SUCCESS, user: response.data.user });
    if (isEditGroup) {
      yield put({ type: UPDATE_USER_IN_GROUP + SUCCESS, user: response.data.user });
    }
    yield call(resolve);
  } catch (error) {
    yield put({ type: EDIT_USER + ERROR, error });
    yield call(reject, error);
  }
}

export function* watchOnEditUser() {
  yield takeEvery(EDIT_USER + REQUEST, onEditUser);
}

export function* removeUser({ userId }) {
  const api = new AxiosHelper().build();

  try {
    yield call(api.delete, apiPath.removeUser + userId);
    yield put({ type: REMOVE_USER + SUCCESS, userId });
  } catch (error) {
    yield put({ type: REMOVE_USER + ERROR, error });
  }
}

export function* watchRemoveUser() {
  yield takeEvery(REMOVE_USER + REQUEST, removeUser);
}

export function* listOfUsers({ page, perPage }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.get, apiPath.listOfUsers + `?page=${page}&per_page=${perPage}`);

    yield put({ type: GET_LIST_OF_USERS + SUCCESS, data: response.data });
  } catch (error) {
    yield put({ type: GET_LIST_OF_USERS + ERROR, error });
  }
}

export default function* watchListOfUsers() {
  yield takeEvery(GET_LIST_OF_USERS + REQUEST, listOfUsers);
}
