import React, { Fragment } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import translate from 'predefined/translate';

export const DashboardComponent = props => {
  const { listOfUsers, listOfGroups, listOfDevices } = props;

  return (
    <Fragment>
      <div className="d-sm-flex align-items-center justify-content-between my-4">
        <h1 className="h3 mb-0 text-gray-800">
          <FormattedMessage id={translate.dashboard.title} />
        </h1>
      </div>

      <div className="row">
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                  <div className="text-s font-weight-normal text-primary text-lowercase mb-1">
                    <FormattedMessage id={translate.dashboard.countOfUsers} />
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{listOfUsers.meta.count}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-calendar fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                <div className="text-s font-weight-normal text-primary text-lowercase mb-1">
                    <FormattedMessage id={translate.dashboard.countOfGroups} />
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{listOfGroups.length}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
            <div className="card-body">
              <div className="row no-gutters align-items-center">
                <div className="col mr-2">
                <div className="text-s font-weight-normal text-primary text-lowercase mb-1">
                    <FormattedMessage id={translate.dashboard.countOfDevices} />
                  </div>
                  <div className="h5 mb-0 font-weight-bold text-gray-800">{listOfDevices.length}</div>
                </div>
                <div className="col-auto">
                  <i className="fas fa-dollar-sign fa-2x text-gray-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default injectIntl(DashboardComponent);
