import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const SignUpModal = props => {
  return (
    <Modal
      {...props}
      onHide={props.hideModal}
      dialogClassName="modal-50w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <FormattedMessage id="signUp.modalSuccess.title" className="justify-content-center" />
          <br />
          <FormattedMessage id="signUp.modalSuccess.text" className="justify-content-center" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer className="justify-content-center">
        <Button onClick={props.hideModal}>
          <FormattedMessage id="btn.close" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SignUpModal;
