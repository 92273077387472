import { takeEvery, call, put } from 'redux-saga/effects';
import { SIGN_IN, REQUEST, SUCCESS, ERROR } from 'constants/actions';
import AxiosHelper from 'axiosHelper';
import { setTokenToStorage } from 'helpers/token';
import { history } from 'helpers/history';
import apiPath from 'predefined/apiPath';

export function* signIn({ values, resolve, reject }) {
  const api = new AxiosHelper().build();

  try {
    const response = yield call(api.post, apiPath.sighIn, values);

    yield call(setTokenToStorage, response.headers);
    yield put({ type: SIGN_IN + SUCCESS, currentUser: response.data.researcher });
    yield call(resolve);
    yield call(history.push, apiPath.basePath);
  } catch (error) {
    yield put({ type: SIGN_IN + ERROR, error });
    yield call(reject, error.response.data);
  }
}

export default function* watchSignIn() {
  yield takeEvery(SIGN_IN + REQUEST, signIn);
}
