import { SIGN_UP, REQUEST, SUCCESS, ERROR } from 'constants/actions';

const initialState = {
  loading: false,
  showModal: false,
};

const signUpReducer = (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SIGN_UP + REQUEST:
      return { ...state, loading: true };
    case SIGN_UP + SUCCESS:
      return { ...state, loading: false, showModal: true };
    case SIGN_UP + ERROR:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default signUpReducer;
