import React from 'react';
import PropTypes from 'prop-types';
import SideBarComponent from 'components/ui/SideBarComponent';

const MainLayout = ({ children }) => (
  <div id="wrapper">
    <SideBarComponent />
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <div className="container-fluid">{children}</div>
      </div>
      <footer className="sticky-footer bg-white">
        <div className="container my-auto">
          <div className="copyright text-center my-auto">
            <span>Copyright &copy; Ink-U-Beta AG 2019</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
);

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
